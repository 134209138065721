import PortfolioChart from "./PortFolioChart";
import { useQuery } from "react-query";
import controllers from "../../../Actions/Controllers";
import { useEffect, useState } from "react";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useAccount } from "wagmi";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import useWalletDetails from "../../../hooks/useWalletDetails";
import { isEmpty } from "lodash";
import PortfolioTable from "./PortfolioTable";
import SpinnerComp from "./SpinnerComp";
import useRegisterError from "../../../hooks/useRegisterError";
export default function PortfolioData({ selectChain }) {
  const [wallet, setWallet] = useState({});
  const [chains, setChains] = useState([]);
  const { connected, address: tronAddress } = useWallet();
  const { isConnected, address } = useAccount();
  const { primaryWallet, isFullyConnected } = useDynamicContext();
  const { getWallet } = useWalletDetails({});
  const { registerError } = useRegisterError({});
  const portfolio = useQuery(
    ["portfolio", isConnected, isFullyConnected, connected, wallet, chains],
    async () => {
      let req = await controllers.fetchPortfolio({
        wallets: { ...wallet },
        chains,
      });
      return await req.json();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      // refetchInterval: 120000,
      enabled: !isEmpty(wallet),
      cacheTime: 1000 * 60 * 60,
      onSuccess: (data) => {
        if (data.status == "error") {
          registerError({ module: "/portfolio", error: data.error });
        }
      },
      onError: (err) => {
        let error = err.details || err.message || "";
        registerError({ module: "/portfolio", error });
      },
    }
  );
  useEffect(() => {
    let tempWallet = {};
    let chainsArray = [];
    if (selectChain[0].name == "All Chains" && selectChain.length <= 1) {
      if (connected) tempWallet.tron = [tronAddress];

      if (isConnected) tempWallet.evm = [address];

      if (isFullyConnected && primaryWallet)
        tempWallet.sol = [primaryWallet?.address];
    } else {
      selectChain.forEach((item) => {
        if (
          item.networkType &&
          typeof tempWallet[item.networkType] === "undefined" &&
          getWallet(item)?.address
        ) {
          tempWallet[item.networkType] = [getWallet(item)?.address];
        }
        if (item.chainId) chainsArray.push(item.chainId);
      });
    }
    setWallet({ ...tempWallet });
    setChains([...chainsArray]);
  }, [selectChain, isFullyConnected, primaryWallet, isConnected, connected]);
  // console.log(portfolio.isLoading, portfolio.isFetching, "portfolio123");
  return (
    <>
      {!portfolio.isLoading ? (
        <>
          <PortfolioChart
            selectChain={selectChain}
            data={portfolio.data?.data || []}
            portfolio={portfolio}
          />
          <PortfolioTable data={portfolio.data?.data?.assets || []} />
        </>
      ) : (
        <SpinnerComp />
      )}
    </>
  );
}
