import useStore from "../../zustand/store";
import inIframe from "../../utils/inIframe";
import Routes from "../Routes";
import Navbar from "../Navbar";
import getParams from "../../utils/getParams";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
export default function WidgetContainer() {
  const configuration = useStore((state) => state.configuration);
  const { width, height } = getParams();
  const userId = useStore((state) => state.userId);
  const setUserId = useStore((state) => state.setUserId);
  useEffect(() => {
    const uid = uuidv4();
    if (userId == null) setUserId(uid);
    else return;
  }, []);
  return (
    <div
      style={{
        width: inIframe() ? "443px" : "443px",
        minHeight: inIframe ? "400px" : "400px",
        borderRadius: "16px",
        padding: "10px",
        paddingTop: "15px",
        paddingBottom: "20px",
        fontFamily: configuration?.customTheme?.fontFamily || "lato",
        boxShadow:
          (configuration &&
            configuration.customTheme &&
            configuration.customTheme?.shadow?.boxShadow) ||
          "1px 1px 7px rgba(0, 0, 0, 0.15)",
        marginTop: inIframe() ? "0px" : "20px",
        marginBottom: inIframe() ? "0px" : "70px",
        ...configuration.containerStyle,
      }}
      className={`${
        inIframe() ? "" : "md:bw-w-[443px] md:bw-min-h-[400px] bw-max-w-[443px]"
      } bw-bg-background-container bw-border bw-border-border-primary  bw-w-full md:bw-mx-auto bw-relative bw-mx-auto blockend-container   ${
        configuration.classNames
      } `}
    >
      <Routes />
      <Navbar />
    </div>
  );
}
