import React from "react";

function Success({ fill, spinnerColor, stopColor }) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="14" height="14.2277" rx="7" fill={fill} />
      <rect x="0.5" y="0.5" width="14" height="14.2277" rx="7" fill={fill} />
      <rect
        x="0.5"
        y="0.5"
        width="14"
        height="14.2277"
        rx="7"
        stroke="#69AEF2"
      />
      <rect
        x="0.5"
        y="0.5"
        width="14"
        height="14.2277"
        rx="7"
        stroke="url(#paint0_linear_3378_15710)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2027 4.53059L6.17782 11.3154L3.14453 8.44987L4.17757 7.32289L5.97789 9.02361L9.99061 3.60547L11.2027 4.53059Z"
        fill="url(#paint1_linear_3378_15710)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3378_15710"
          x1="16.3198"
          y1="5.20289"
          x2="-7.03721"
          y2="5.17811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={spinnerColor ? spinnerColor : "#2CFFE4"} />
          <stop offset="1" stop-color={stopColor ? stopColor : "#A45EFF"} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3378_15710"
          x1="11.9117"
          y1="6.23973"
          x2="-0.63592"
          y2="6.22561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={spinnerColor ? spinnerColor : "#2CFFE4"} />
          <stop offset="1" stop-color={stopColor ? stopColor : "#A45EFF"} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Success;
