import React from "react";
import useStore from "../../zustand/store";
import { isEmpty } from "lodash";
function ProviderCompDetails({ details }) {
  const persist = {
    route: {
      ...details,
    },
  };
  const configuration = useStore((state) => state.configuration);
  return !isEmpty(persist.route) ? (
    <div className="bw-relative bw-mt-4 bw-flex bw-justify-center ">
      <div
        style={{
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "1px 1px 10px 3px rgba(0, 0, 0, 0.15)",
        }}
        className="bw-w-[200px] bw-rounded-[50%] bw-absolute bw-right-auto bw-z-0 bw-left-auto bw-h-[40px]"
      ></div>
      <div className="bw-flex bw-relative bw-bg-background-container bw-z-10 bw-mx-auto  bw-h-[41px] bw-w-[70%] bw-border-[0.5px] bw-border-border-primary bw-border-r-0 bw-border-l-0 bw-items-center bw-gap-x-1">
        {" "}
        <p className="bw-text-sm bw-w-full bw-text-center bw-flex bw-justify-center bw-items-center bw-font-normal bw-my-1 bw-text-text-primary">
          <span className="bw-text-sm bw-font-light">via</span>{" "}
          <img
            src={persist?.route?.providerDetails?.logoUrl}
            className="bw-rounded-[50%] bw-mx-[4px] bw-min-w-[18px] bw-max-h-[18px] bw-min-h-[18px]"
            width={18}
            height={18}
          />
          {persist?.route?.providerDetails?.name || ""}:{" "}
          {persist?.route?.protocolsUsed[0]}
          {persist?.route?.protocolsUsed?.length <= 1 ? (
            ""
          ) : (
            <span className="bw-ml-1 bw-text-xs bw-font-normal bw-leading-6">
              {`+${persist?.route?.protocolsUsed?.length - 1}`} More
            </span>
          )}{" "}
        </p>{" "}
      </div>
    </div>
  ) : (
    <div className="bw-relative bw-w-full bw-h-[41px] bw-mt-4 bw-flex bw-justify-center"></div>
  );
}

export default ProviderCompDetails;
