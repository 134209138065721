export default function validError(errorText) {
  if (!errorText.length) {
    return false;
  } else if (errorText == "Request rejected") {
    return false;
  } else if (
    errorText == "User rejected the request" ||
    errorText.includes("User rejected the request")
  ) {
    return false;
  } else {
    return true;
  }
}
