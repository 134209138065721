export default function getAddressType(address) {
  let checkRegex = address.match(/[1-9A-HJ-NP-Za-km-z]{32,44}/);
  if (address.slice(0, 2) === "0x") {
    return true;
  } else if (checkRegex) {
    return address == checkRegex;
  } else {
    return false;
  }
}
