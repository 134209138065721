import React from "react";

function TxnNav({ fill, stroke }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="256"
        cy="256"
        r="244"
        fill={fill}
        stroke={stroke}
        stroke-width="24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M199.293 338.2L307.498 256.356L199.293 174.512L199.293 126.352L371.222 256.395L199.293 386.439L199.293 338.2Z"
        fill={stroke}
      />
    </svg>
  );
}

export default TxnNav;
