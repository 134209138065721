import React from "react";

function GradTick({ spinnerColor, stopColor }) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1513 1.80097L5.88848 14.1211L0.296875 8.9178L2.20119 6.87138L5.51991 9.95963L12.917 0.121094L15.1513 1.80097Z"
        fill="url(#paint0_linear_3378_15206)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3378_15206"
          x1="16.4584"
          y1="4.90453"
          x2="-6.67205"
          y2="4.8781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={spinnerColor ? spinnerColor : "#2CFFE4"} />
          <stop offset="1" stop-color={stopColor ? stopColor : "#A45EFF"} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default GradTick;
