import truncate from "../../utils/truncate";
import useStore from "../../zustand/store";
import fixChar from "../../utils/fixChar";
export default function TokenBox({ chainData, coinData, amount }) {
  const configuration = useStore((state) => state.configuration);
  return (
    <div
      style={{
        boxShadow:
          (configuration &&
            configuration.customTheme &&
            configuration.customTheme?.shadow?.boxShadow) ||
          "1px 1px 7px rgba(0, 0, 0, 0.15)",
      }}
      className="bw-bg-background-card  bw-relative bw-z-10 bw-min-w-[50%] bw-px-2 md:bw-min-w-[153px] bw-flex bw-flex-col bw-items-center bw-justify-center bw-rounded-md bw-h-[63px] bw-border bw-border-border-primary"
    >
      <div
        className={`${
          coinData?.symbol?.length > 6 ? "bw-text-base" : "bw-text-lg"
        } bw-text-center bw-font-medium bw-text-text-primary`}
      >
        <span>{amount ? fixChar(amount, 8) : 0}</span>{" "}
        <span>{coinData?.symbol || ""}</span>
        <p className="bw-text-sm bw-font-medium bw-text-text-secondary">{`on ${chainData?.name}`}</p>
      </div>
    </div>
  );
}
