import Tooltip from "@mui/material/Tooltip";

export default function Coins({ name, children }) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "var(--tw-background-container)",
            "& .MuiTooltip-arrow": {
              color: "var(--tw-bg-container)",
            },
            border: "1px solid var(--tw-border-primary)",
            color: "var(--tw-text-primary)",
          },
        },
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      className="bw-text-text-button"
      title={name}
      placement="top-start"
    >
      {children}
    </Tooltip>
  );
}
