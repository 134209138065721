import React, { useEffect, useRef } from "react";
import Exchange from "../Exchange";
import useStore from "../../zustand/store";
export default function ConfirmDetails({
  handleConfirmClose,
  routesData,
  amount,
  resetAmount,
  resetBalance,
  handleStopRoute,
}) {
  const prevRoute = useRef();

  useEffect(() => {
    if (
      !prevRoute.current &&
      Number(
        prevRoute?.current?.minOutputAmount ||
          prevRoute?.current?.outputAmountDisplay
      ) !==
        Number(routesData?.minOutputAmount || routesData?.outputAmountDisplay)
    )
      prevRoute.current = routesData;
  }, [routesData]);
  useEffect(() => {
    handleStopRoute(true);
  }, []);
  function handleOpenExchange() {
    handleStopRoute(false);
    handleConfirmClose(false);
  }
  function setExchange() {
    handleConfirmClose(false);
  }
  return (
    <Exchange
      handleOpenExchange={handleOpenExchange}
      amount={amount}
      route={routesData}
      resetAmount={resetAmount}
      resetBalance={resetBalance}
      setExchange={setExchange}
    />
  );
  // );
}
