export default function SortUp({ isSelected, fill }) {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 5 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.69528 0.665039L4.91444 4.50874H0.476118L2.69528 0.665039Z"
        fill={fill}
        fill-opacity={isSelected ? "1" : "0.2"}
      />
    </svg>
  );
}
