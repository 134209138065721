import React, { useEffect, useState, useContext, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import controllers from "../../Actions/Controllers";
import useStore from "../../zustand/store";
import { isEmpty } from "lodash";
import {
  useSendTransaction,
  usePrepareSendTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import SuccessCard from "./SuccessCard";
import useSolanaTxn from "../../hooks/metamask/useSolanaTxn";
import useTronTxn from "../../hooks/useTronTxn";
import { TxnSuccessContext } from "../Context/txnSuccessContext";
import { TxnErrorContext } from "../Context/txnErrorContext";
import TxnExeButton from "./TxnExeButton";
import TransactionFailedCTA from "./TransactionFailedCTA";
import TxnStatusComp from "./TxnStatusComp";
import TransactionLinkComp from "./TransactionLinkComp";
import Steps from "./steps";
import StepsIcons from "./stepsIcons";
import TxnLoad from "../Animation/TxnLoad";
import config from "../../service/config";
import UpdateQuote from "./UpdateQuote";
import useRegisterError from "../../hooks/useRegisterError";
import validError from "../../utils/validError";
let interval;
let ws;
// let count = 1;
function TransactionComp({
  isTimerStarted,
  setExchange,
  amount,
  startTimer,
  handleBack,
  details,
}) {
  //zustand
  const persist = useStore((state) => state.persist);
  const setPersist = useStore((state) => state.setPersist);

  const isMounted = useRef(false);
  //states
  const [allSteps, setAllSteps] = useState({ steps: null, currentStep: 0 });
  const [stepData, setStepData] = useState({});
  const [disableButton, setDisableButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [isTryAgain, setIsTryAgain] = useState(false);
  //context
  const { handleTxnSuccess } = useContext(TxnSuccessContext);
  const { isErrorMessage, handleErrorMessage } = useContext(TxnErrorContext);
  const { chain } = useNetwork();
  const prepare = usePrepareSendTransaction({
    to: useStore.getState()?.persist?.toCoin?.address,
    value: 0,
  });
  const isHistory = persist.isHistory;
  // console.log(isHistory, "isHistory");
  //txn hooks
  const { data, isError, error, sendTransaction, reset } = useSendTransaction({
    ...prepare,
    ...(useStore.getState()?.persist?.txnEvm || {}),
    value: persist?.txnEvm?.value ? persist?.txnEvm?.value : "0",
  });
  const { registerError } = useRegisterError();
  // console.log(chain, "chain123");
  const { switchNetwork } = useSwitchNetwork({
    chainId: Number(persist?.stepData?.from?.chainId || "0"),
    throwForSwitchChainNotSupported: true,
    onError(error) {
      console.log("Error", error);
    },
  });
  // console.log(
  //   {
  //     ...prepare,
  //     ...(persist?.txnEvm || {}),
  //     value: persist?.txnEvm?.value ? persist?.txnEvm?.value : "0",
  //   },
  //   persist?.txnEvm,
  //   data,
  //   error,
  //   "transaction evm"
  // );
  const isHistoryData = !isEmpty(useStore.getState()?.persist?.historyData);
  const { solData, solError, solErrMessage, handleTxn, resetSolData } =
    useSolanaTxn();
  const { tronData, tronError, handleTronTxn, resetTronData } = useTronTxn();
  // console.log(isErrorMessage, "isErrorMessage");
  let txnTextObj =
    persist?.allSteps?.steps?.[
      allSteps?.currentStep || persist?.allSteps?.currentStep
    ]?.texts;
  let txnProcessType = disableButton ? "process" : "pre";
  let errorText = errorMessage?.error?.length
    ? errorMessage.error
    : error?.message?.length > 25
    ? error?.message?.substring(0, 25) + "..."
    : error?.message || solErrMessage?.message || tronError?.message;
  // history logics
  useEffect(() => {
    if (persist?.allSteps && isHistory) {
      setAllSteps(persist.allSteps);
    }
  }, [persist.allSteps]);
  useEffect(() => {
    if (
      isHistoryData &&
      typeof persist?.historyData?.currentStepIndex !== "undefined" &&
      persist?.historyData?.currentStepStatus == "not-started"
    ) {
      callNextTx(persist?.stepData, persist?.routeId);
    } else if (persist?.historyData?.currentStepStatus == "in-progress") {
      setDisableButton(true);
      // callStatus();
      startTimer(true);
    }
  }, [isHistoryData]);
  // console.log(disableButton, "disableButton");
  //  history logics end
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const fetchStatus = useMutation(
    "status",
    async ({ routeId, stepId, txnHash }) => {
      let res = await controllers.fetchStatus(routeId, stepId, txnHash);
      return res.json();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        if (data?.status === "error") {
          callRegisterError("/submitTxn", data);
        }
        handleStatus(data);
      },
      onError: (err) => {
        let error = err.details || err.message || "";
        setErrorMessage({ error });
        callRegisterError("/submitTxn", { error });
      },
    }
  );
  function callRegisterError(module, data) {
    registerError({
      module,
      routeId: persist?.routeId || "",
      stepId: persist?.stepData.stepId || "",
      error: data.error,
      userWalletAddress: persist?.route?.userWlletAddress,
    });
  }
  const nextTx = useMutation(
    "nexttx",
    async ({ id, routeId }) => {
      let res = await controllers.fetchNextTx(id, routeId);
      return await res.json();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 1000,
      onSuccess: (data, variables) => {
        if (isMounted.current) {
          if (data.status == "error") {
            setErrorMessage(data);
            callRegisterError("/nextTx", data);
          } else {
            setErrorMessage({ error: "" });
          }
          handleSkipTxn(data);
          setDisableButton(false);
          // count > 1 &&
          setPersist({
            ...useStore.getState()?.persist,
            loading: false,
            txnEvm: data?.data?.txnData?.txnEvm,
            nextTx: data,
            hash: null,
          });
          if (!allSteps.steps && !persist?.allSteps?.steps) {
            setPersist({
              ...useStore.getState().persist,
              isQuoteTimer: data.status == "error" ? false : true,
              quote: data.status == "error" ? "disable" : "enable",
              quoteStartTime: new Date().getTime(),
            });
            // setAllSteps({
            //   steps:
            //     txnBody?.data?.data?.steps || persist?.allSteps?.steps || [],
            //   currentStep: 0,
            //   type: "preText",
            // });
          }
        } else {
          if (persist?.allSteps?.currentStep === 0) {
            setPersist({
              ...useStore.getState().persist,
              isQuoteTimer: false,
              quoteStartTime: new Date().getTime(),
            });
          }
        }
      },
      onError: (err) => {
        let error = err.details || err.message || "";
        setErrorMessage({ error });
        callRegisterError("/nextTx", { error });
      },
    }
  );
  const txnBody = useQuery(
    ["txnbody", persist?.routeId],
    async () => {
      let res = await controllers.fetchTxnBody(
        `/createTx?routeId=${persist?.routeId || ""}`
      );
      return await res.json();
    },

    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        if (data.status == "error") {
          setErrorMessage(data);
          callRegisterError("/createTx", data);
        } else {
          setErrorMessage({ error: "" });
        }
        if (typeof data?.data !== "undefined") {
          setTimeout(() => {
            callNextTx(data?.data?.steps[0], data?.data?.routeId);
            setStepData(data?.data?.steps[0]);
            setAllSteps({
              steps: data?.data?.steps || persist?.allSteps?.steps || [],
              currentStep: 0,
              type: "preText",
            });
          }, 0);
          if (data?.data?.status == "error") {
            setErrorMessage({ ...data.data });
          }
        }
      },
      onError: (err) => {
        console.log(err);
        let error = err.details || err.message || "";
        setErrorMessage({ error });
        callRegisterError("/createTx", { error });
      },
      enabled:
        persist.routeId?.length && !persist.nextTx && !Boolean(isHistory)
          ? true
          : false,
    }
  );
  useEffect(() => {
    let walletError =
      error?.message?.length ||
      solErrMessage?.message?.length ||
      tronError?.message?.length;
    let walletErrorText =
      error?.message || solErrMessage?.message || tronError?.message || "";
    let isValidError = validError(walletErrorText);
    if (walletError && isValidError) {
      callRegisterError("/wallet error", {
        error: walletErrorText,
      });
    }
  }, [error?.message, solErrMessage?.message, tronError?.message]);

  // console.log(
  //   !persist.nextTx,
  //   persist.routeId?.length,
  //   isHistory,
  //   "createtxcall"
  // );
  useEffect(() => {
    if (
      (!fetchStatus.isFetching || persist.statusData?.status == "success") &&
      allSteps.currentStep === allSteps.steps?.length &&
      (txnBody.isSuccess || persist.isSuccess)
    ) {
      handleTxnSuccess(true);
    } else {
      handleTxnSuccess(false);
    }
  }, [
    fetchStatus.isFetching,
    fetchStatus.data,
    allSteps,
    txnBody.isSuccess,
    persist.isSuccess,
    persist?.statusData,
  ]);

  useEffect(() => {
    if (
      isError ||
      solError ||
      tronError?.length ||
      errorMessage?.error?.length ||
      persist.statusData?.status == "failed"
    ) {
      handleErrorMessage(true);
    } else {
      handleErrorMessage(false);
    }
  }, [
    isError,
    solError,
    tronError?.length,
    errorMessage,
    fetchStatus?.data,
    persist?.statusData,
  ]);
  useEffect(() => {
    if (isErrorMessage) reset();
  }, []);

  useEffect(() => {
    if (
      (!isError ||
        !solError ||
        !tronError?.length ||
        !errorMessage?.error?.length) &&
      (persist?.hash || data || solData?.signature || tronData?.txid) &&
      fetchStatus?.data?.data?.srcTxnHash !==
        (data?.hash || solData?.signature || tronData?.txid || persist?.hash)
    ) {
      setAllSteps((prev) => {
        return { ...prev, type: "postText" };
      });
    }
  }, [
    isError,
    solError,
    tronError,
    data,
    solData,
    tronData,
    errorMessage?.error,
    persist?.hash,
  ]);
  useEffect(() => {
    let tempPersist = { ...useStore.getState()?.persist };
    if (allSteps.steps !== null) {
      tempPersist = {
        ...tempPersist,
        allSteps,
        stepStatus: handleStepStatus(),
      };
    }
    if (data?.hash || solData?.signature || tronData?.txid) {
      tempPersist = {
        ...tempPersist,
        hash: data?.hash || solData?.signature || tronData?.txid,
      };
    }
    if (!isEmpty(stepData)) {
      tempPersist = { ...tempPersist, stepData };
    }

    persist?.routeId && setPersist({ ...tempPersist, isSuccess: true });
  }, [allSteps, data, solData, tronData, stepData]);
  useEffect(() => {
    if (!isEmpty(persist?.allSteps)) {
      if (data?.hash || solData?.signature || tronData?.txid || persist?.hash) {
        setAllSteps(persist?.allSteps);
      } else {
        setAllSteps({ ...persist?.allSteps, type: "preText" });
      }
    }
    if (typeof persist?.loading !== "undefined") {
      if (data?.hash || solData?.signature || tronData?.txid || persist?.hash) {
        setDisableButton(persist?.loading);
      } else {
        setDisableButton(false);
      }
    }
    if (persist?.routeId) {
      !isHistory && setExchange();
    }
    handleSkipTxn(persist?.nextTx);
    if (persist?.nextTx?.status === "error") {
      setDisableButton(true);
      callNextTx(persist?.stepData, persist?.routeId);
    }
  }, []);
  async function callStatus() {
    // console.log(
    //   persist?.routeId,
    //   persist?.hash,
    //   persist?.stepData?.stepId,
    //   "stepid123"
    // );
    ws = new WebSocket(config.WS);
    ws.onopen = async function () {
      // console.log("connected ws");
      ws.send(
        JSON.stringify({
          type: "subscribe",
          channel: stepData?.stepId || persist?.stepData?.stepId,
        })
      );
    };
    ws.onmessage = function (msg) {
      let data = JSON.parse(msg.data);
      let socketData = { data };
      handleStatus(socketData?.data);
    };

    try {
      await fetchStatus.mutateAsync({
        routeId: persist?.routeId || txnBody.data?.data?.routeId,
        stepId: stepData?.stepId || persist?.stepData?.stepId,
        txnHash:
          persist?.hash ||
          (persist?.fromChain?.networkType === "sol"
            ? solData.signature
            : persist?.fromChain?.networkType === "tron"
            ? tronData?.txid
            : data.hash),
      });
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (
      (!isError ||
        !solError ||
        !tronError?.length ||
        !errorMessage?.error?.length) &&
      (persist?.hash || data || solData?.signature || tronData?.txid) &&
      allSteps.currentStep <= allSteps?.steps?.length - 1 &&
      fetchStatus?.data?.data?.srcTxnHash !==
        (data?.hash || solData?.signature || tronData?.txid || persist?.hash)
    ) {
      interval = setTimeout(() => {
        callStatus();
      }, 1000);
    } else {
      clearInterval(interval);
      if (ws) ws.close();
    }

    return () => {
      if (interval) clearInterval(interval);
      if (ws) ws.close();
    };
  }, [data, solData, tronData, isErrorMessage, allSteps, persist?.hash]);

  useEffect(() => {
    if (isErrorMessage && !isTryAgain) {
      setDisableButton(false);
      setPersist({ ...useStore.getState()?.persist, loading: false });
    } else {
      setPersist({ ...useStore.getState()?.persist, loading: true });
    }
  }, [isErrorMessage, isTryAgain]);
  function handleStepStatus(status) {
    let stepStatus = [...(useStore.getState()?.persist?.stepStatus || [])];
    if (stepStatus.length) {
      stepStatus[allSteps?.currentStep] = {
        ...stepStatus[allSteps?.currentStep],
        type: allSteps?.type,
      };
    } else {
      stepStatus[0] = {
        ...stepStatus[allSteps?.currentStep],
        type: allSteps?.type,
      };
    }
    if (status) {
      stepStatus[allSteps?.currentStep] = {
        ...stepStatus[allSteps?.currentStep],
        ...status,
      };
    }
    return stepStatus;
  }
  // console.log(allSteps, "allsteps");
  function handleTxnObj(data) {
    let persistedTxnHashObj = useStore?.getState()?.persist?.txnHashObj || {};
    let tempTxnObj = { ...persistedTxnHashObj };
    if (!isEmpty(tempTxnObj[data?.data?.stepId])) {
      if (!tempTxnObj[data?.data?.stepId]?.destTxnHash?.length) {
        tempTxnObj[data?.data?.stepId] = {
          ...tempTxnObj[data?.data?.stepId],
          destTxnHash: data?.data?.destTxnHash || data?.destTxnHash || "",
        };
      }
      if (!tempTxnObj[data?.data?.stepId]?.destTxnUrl?.length) {
        tempTxnObj[data?.data?.stepId] = {
          ...tempTxnObj[data?.data?.stepId],
          destTxnUrl: data?.data?.destTxnUrl || data?.destTxnUrl || "",
        };
      }
      setPersist({
        ...useStore?.getState()?.persist,
        txnHashObj: tempTxnObj,
      });
    } else {
      tempTxnObj[data?.data?.stepId] = {
        srcTxnUrl: data?.data?.srcTxnUrl || "",
        stepId: data?.data?.stepId || "",
        srcTxnHash: data?.data?.srcTxnHash || "",
        destTxnUrl: data?.data?.destTxnUrl || "",
        destTxnHash: data?.data?.destTxnHash || "",
      };
      setPersist({
        ...useStore?.getState()?.persist,
        txnHashObj: tempTxnObj,
      });
    }
  }
  function setNextStep() {
    setAllSteps({
      ...allSteps,
      steps: txnBody?.data?.data?.steps || persist?.allSteps?.steps || [],
      currentStep: allSteps.currentStep + 1,
      type: "preText",
    });
  }
  function handleStatus(data) {
    if (typeof data?.ack == "undefined") {
      if (data?.data?.status) {
        setPersist({
          ...useStore?.getState()?.persist,
          statusData: data?.data,
          stepStatus: handleStepStatus({
            status:
              data?.data?.status == "in-progress"
                ? "pending"
                : data?.data?.status,
          }),
        });
      }
      handleTxnObj(data);
      if (data.status == "error") {
        setErrorMessage(data);
      }
      if (data.data?.status == "success") {
        setErrorMessage({ error: "" });
        clearInterval(interval);
        setStepData(allSteps.steps[allSteps.currentStep + 1]);
        if (allSteps.currentStep == allSteps?.steps?.length - 1) {
          setAllSteps({
            ...allSteps,
            currentStep: allSteps.currentStep + 1,
            type: "preText",
          });
        } else {
          setNextStep();
          callNextTx(
            allSteps.steps[allSteps.currentStep + 1],
            persist?.routeId || txnBody.data?.data?.routeId
          );
        }
      } else if (data.data?.status == "failed") {
        clearInterval(interval);
      } else if (data.data?.status == "in-progress") {
        if (errorMessage?.error?.length) {
          setDisableButton(true);
          setPersist({ ...useStore.getState()?.persist, loading: true });
          setErrorMessage({ error: "" });
        }
      }
    } else {
      return;
    }
  }

  function handleExeTxn() {
    if (persist?.fromChain?.networkType == "sol") {
      handleTxn(
        persist?.amount || amount,
        persist?.toChain?.networkType,
        persist?.nextTx?.data || nextTx.data?.data
      );
    } else if (persist?.fromChain?.networkType == "tron") {
      handleTronTxn(persist?.nextTx?.data || nextTx.data?.data);
    } else {
      // console.log("senttx called", persist.txnEvm);
      sendTransaction();
    }
  }
  function handleDisable(bool) {
    setIsTryAgain(bool);
  }
  async function handleStep() {
    if (
      allSteps.steps?.[allSteps?.currentStep || 0] ||
      persist?.allSteps.steps?.[persist?.allSteps?.currentStep || 0]
    ) {
      setDisableButton(true);
      setPersist({
        ...useStore.getState()?.persist,
        loading: true,
        isTimerStarted: true,
        statusData: null,
        quote: "disable",
        startTime:
          useStore?.getState()?.persist?.startTime || new Date().getTime(),
      });
      startTimer(true);
      handleExeTxn();
      setAllSteps((prev) => {
        return { ...prev, type: "loadingText" };
      });
    } else {
    }
  }
  function handleSkipTxn(data) {
    if (data?.data?.skipTxn === true) {
      let stepId =
        txnBody?.data?.data?.steps?.[allSteps.currentStep + 1] ||
        persist?.allSteps?.steps?.[allSteps.currentStep + 1] ||
        {};
      if (!isEmpty(stepId) && data?.data?.routeId) {
        setTimeout(() => {
          setNextStep();
          callNextTx(stepId, data?.data?.routeId);
          setDisableButton(true);
        }, 0);
      }
    } else {
      return;
    }
  }
  useEffect(() => {
    if (useStore.getState()?.persist?.nextTx?.data && isTryAgain) {
      handleStep();
      setIsTryAgain(false);
    }
  }, [useStore.getState()?.persist?.nextTx?.data]);
  // console.log(isTryAgain, "istryagain");
  async function callNextTx(stepval, routeval, isTryAgain) {
    try {
      await nextTx.mutateAsync({
        id: stepval?.stepId,
        routeId: routeval,
      });
      setStepData(stepval);
    } catch (err) {
      console.log(err);
    }
  }

  function handleErrorReset() {
    if (persist.fromChain?.networkType === "evm") {
      reset();
      setErrorMessage({ error: "" });
    } else if (persist.fromChain?.networkType === "sol") {
      resetSolData();
    } else {
      resetTronData();
    }
  }
  function handleTryAgain() {
    // count = count + 1;
    setDisableButton(true);
    setPersist({
      ...useStore.getState()?.persist,
      stepStatus: handleStepStatus({ status: "loadingText" }),
    });
    handleErrorMessage(false);
    handleErrorReset();
    callNextTx(
      persist?.allSteps?.steps[allSteps.currentStep] ||
        allSteps.steps[allSteps.currentStep] ||
        {},
      persist?.routeId || txnBody.data?.data?.routeId,
      true
    );
  }
  useEffect(() => {
    if (persist?.stepData?.from?.chainId) {
      if (
        Number(persist?.stepData?.from?.chainId) !== chain?.chainId &&
        persist?.fromChain?.networkType === "evm"
      ) {
        switchNetwork();
      }
    } else return;
  }, [persist?.stepData]);
  function handleUpdate() {
    setStepData({});
    setAllSteps({ steps: null, currentStep: 0 });
  }
  const isShowNewQuote =
    !persist.isQuoteTimer &&
    !persist.hash &&
    persist.quote == "enable" &&
    persist?.allSteps?.currentStep == 0 &&
    persist?.nextTx?.status === "success";
  console.log(nextTx.isSuccess, allSteps, "nextxt");
  return (
    <>
      <div
        className={`bw-pt-4 bw-h-[80%] bw-mt-4 bw-relative  ${
          isShowNewQuote ? "bw-pointer-events-none bw-opacity-60" : ""
        }`}
      >
        {!txnBody.isFetching && !isEmpty(persist?.stepData) ? (
          (txnBody.isSuccess && txnBody.data) || persist?.routeId ? (
            (txnBody.data?.data?.steps || persist?.allSteps?.steps)?.map(
              (item, i, arr) => {
                // console.log(item, txnBody, "steparray");
                return (
                  <>
                    {" "}
                    <div className="bw-flex bw-items-start bw-mb-4 bw-justify-between">
                      <div className="bw-flex  bw-relative bw-items-start  bw-w-max bw-justify-center bw-gap-x-3">
                        <StepsIcons
                          allSteps={allSteps}
                          disableButton={disableButton}
                          i={i}
                          arr={arr}
                        />
                        <Steps item={item} i={i} />
                      </div>
                      <TransactionLinkComp i={i} item={item} />
                    </div>
                  </>
                );
              }
            )
          ) : (
            <></>
          )
        ) : (
          <div className="bw-w-full bw-flex-1 bw-h-full bw-mt-[-5%] bw-flex bw-flex-col bw-justify-center bw-items-center ">
            {/* <LoaderStep /> */}
            {!errorMessage?.error ? (
              <>
                <div className="bw-relative bw-pb-4">
                  <TxnLoad />
                </div>
                <p className="bw-text-2xl bw-text-center   bw-font-medium bw-text-text-secondary">
                  Initializing Transaction...
                </p>
              </>
            ) : (
              <></>
            )}
            <p className="bw-text-text-error  bw-w-full bw-text-sm bw-text-center bw-text-text-redtext">
              {errorMessage?.error}
            </p>
          </div>
        )}
      </div>
      {!txnBody.isFetching && !isEmpty(persist?.stepData) ? (
        isShowNewQuote ? (
          <UpdateQuote
            handleUpdate={handleUpdate}
            startTimer={startTimer}
            setDisableButton={setDisableButton}
            reset={handleErrorReset}
            details={details}
          />
        ) : (!fetchStatus.isFetching ||
            persist.statusData?.status == "success" ||
            persist.statusData?.status == "failed") &&
          allSteps.currentStep !== allSteps.steps?.length ? (
          isErrorMessage ? (
            <TransactionFailedCTA
              errorText={errorText}
              handleGoBack={handleBack}
              txnTextObj={txnTextObj}
              handleExeTxn={handleTryAgain}
              handleDisable={handleDisable}
            />
          ) : (
            <div className="bw-absolute bw-bg-background-container bw-border-t bw-pt-3 bw-border-border-primary bw-w-full bw-flex bw-flex-col bw-items-center bw-justify-center bw-bottom-0">
              <TxnStatusComp
                txnProcessType={txnProcessType}
                txnTextObj={txnTextObj}
                errorText={errorText}
                allSteps={allSteps || persist?.allSteps}
              />
              <TxnExeButton
                txnProcessType={txnProcessType}
                txnTextObj={txnTextObj}
                disableButton={disableButton}
                handleStep={handleStep}
              />
            </div>
          )
        ) : txnBody.isSuccess || persist.isSuccess ? (
          <SuccessCard handleBack={handleBack} />
        ) : (
          <>Loading...</>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default TransactionComp;
