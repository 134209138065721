import React from "react";

function FastestSvg({ fill }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.16797"
        y="0.666992"
        width="14"
        height="14"
        rx="7"
        fill={fill}
      />
      <rect
        x="1.16797"
        y="0.666992"
        width="14"
        height="14"
        rx="7"
        fill={fill}
      />
      <rect
        x="1.16797"
        y="0.666992"
        width="14"
        height="14"
        rx="7"
        stroke="#FFC700"
      />
      <path
        d="M4.61719 8.06204L10.1435 3.3252L7.77508 6.48309H12.1172L4.61719 12.0094L8.16982 8.06204H4.61719Z"
        fill="#FFC700"
      />
    </svg>
  );
}

export default FastestSvg;
