import React, { useContext, useState } from "react";
import { useTimer } from "react-timer-hook";
import useStore from "../../zustand/store";
import FormatTime from "../../utils/formatTime";
import { TxnSuccessContext } from "../Context/txnSuccessContext";
import TimeLoader from "../Animation/TimeLoader";
import images from "../../images";
function Timer({ handleExpiry }) {
  const persist = useStore((state) => state.persist);
  const route = persist?.route || {};
  let startTime = persist?.startTime || persist?.historyData?.createdAt;
  const { minutes, seconds } = useTimer({
    expiryTimestamp: startTime + route?.estimatedTimeInSeconds * 1000,
    onExpire: () => {
      handleExpiry();
    },
  });
  return `${FormatTime(minutes)}:${FormatTime(seconds)}`;
}
export default React.memo(function TxnTimer({
  route,
  isTimerStarted,
  startTimer,
  isErrorMessage,
}) {
  const [expired, setExpired] = useState(false);
  const { isTxnSuccessful } = useContext(TxnSuccessContext);
  const persist = useStore((state) => state.persist);
  const isLoading = !persist.stepData;
  const { errornewicon } = images;
  function handleExpiry() {
    setExpired(true);
    startTimer(false);
  }
  return (
    <div
      className={`bw-border bw-w-[65px] bw-mt-5 bw-h-[65px] bw-flex bw-items-center bw-justify-center bw-rounded-[50%] bw-bg-background-card  bw-relative bw-z-10  hover:bw-opacity-100  bw-border-border-primary `}
    >
      <p
        className={` bw-text-sm bw-text-center  bw-font-medium  bw-text-text-primary`}
      >
        {isLoading ? (
          <TimeLoader />
        ) : isTxnSuccessful ? (
          "Done"
        ) : isTimerStarted && !expired ? (
          <Timer route={route} handleExpiry={handleExpiry} />
        ) : expired ? (
          <TimeLoader />
        ) : (
          "Start Txn"
        )}
      </p>
    </div>
  );
});
