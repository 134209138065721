import {
  useDynamicContext,
  useWalletItemActions,
  useUserWallets,
} from "@dynamic-labs/sdk-react-core";
import { useEffect, useState } from "react";
import images from "../../images";
import { CircularProgress } from "@mui/material";
import InstalledIcon from "../InstalledIcon";
import useStore from "../../zustand/store";
import useWalletDetails from "../../hooks/useWalletDetails";
import styles from "../SelectWallet/SelectWallet.module.css";
const Main = ({
  fromChain,
  toChain,
  connectWalletField,
  handleSolConnecting,
  selectedWalletTab,
  handleShowWallet,
}) => {
  const { primaryWallet, isFullyConnected, handleLogOut, setPrimaryWallet } =
    useDynamicContext();
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { setShowAuthFlow, walletConnectorOptions } = useDynamicContext();
  const { openWallet } = useWalletItemActions();
  const configuration = useStore((state) => state.configuration);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const { handleSOLDisable } = useWalletDetails({ fromChain, toChain });
  const isSOLDisable = handleSOLDisable(connectWalletField);
  const wallets = useUserWallets();

  // console.log(primaryWallet, "primaryWallet");
  useEffect(() => {
    if (primaryWallet) {
      primaryWallet.connector.getBalance().then((balance) => {
        setBalance(balance.toString());
      });
    }
  }, [primaryWallet]);
  useEffect(() => {
    if (isFullyConnected && isLoading && primaryWallet !== null) {
      setIsLoading(false);
    }
  }, [isFullyConnected, primaryWallet]);
  const {
    phantom,
    solflare,
    slope,
    installedicon,
    magiceden,
    backpacksol,
    keplr,
  } = images;
  const walletIcons = {
    solflare,
    phantom,
    slope,
    backpacksol,
    "magic eden (solana)": magiceden,
    keplr,
  };
  // console.log(primaryWallet, wallets, "primarywallet");
  return walletConnectorOptions?.length ? (
    <>
      {walletConnectorOptions
        .filter((item, i) => {
          if (
            ((item.name == "Solflare" ||
              item.name == "Slope" ||
              item.name == "Phantom" ||
              item.name == "BackpackSol" ||
              item.name == "Magic Eden (Solana)") &&
              selectedWalletTab !== "Cosmos") ||
            (item.name == "Keplr" &&
              (selectedWalletTab === "Cosmos" || selectedWalletTab === "All"))
          ) {
            return true;
          }
        })
        .map((item, i) => {
          // console.log(walletConnectorOptions, "walletconnectoroptions");
          return (
            <div
              style={
                item.walletConnector?.name == primaryWallet?.connector?.name
                  ? {
                      ...configuration?.gradientStyle,
                    }
                  : {}
              }
              className={`bw-w-[47%] bw-mb-4 ${
                item.name == "Keplr" && selectedWalletTab === "Cosmos"
                  ? ""
                  : "  "
              } bw-rounded-md bw-p-[1px] ${
                !configuration.gradientStyle &&
                item.walletConnector?.name == primaryWallet?.connector?.name
                  ? styles.gradientbutton
                  : "bw-bg-border-primary"
              } `}
            >
              <div
                className={` bw-relative bw-flex bw-w-full bw-bg-background-container  hover:bw-shadow-sm bw-font-medium  hover:bw-font-bold hover:bw-text-text-primary  bw-items-center bw-gap-x-3  bw-rounded-md  bw-h-[56px] bw-px-3 bw-cursor-pointer  ${
                  ""
                  // isFullyConnected ? "bw-pointer-events-none bw-opacity-50" : ""
                }${
                  item.walletConnector?.name == primaryWallet?.connector?.name
                    ? " bw-text-text-primary"
                    : "bw-text-text-secondary"
                }`}
                onClick={() => {
                  if (
                    item.walletConnector?.name == primaryWallet?.connector?.name
                  ) {
                    if (connectWalletField == "to") {
                      setToDisconnect(false);
                      handleShowWallet();
                    } else {
                      handleLogOut();
                    }
                  } else {
                    primaryWallet?.address?.length && handleLogOut();
                    openWallet(item.name?.toLowerCase());
                    setIsLoading(true);
                    handleSolConnecting(true, item.name?.toLowerCase());
                  }
                }}
              >
                {/* {item.isInstalledOnBrowser ? (
                <div className="bw-absolute bw-top-1 bw-right-0">
                  <InstalledIcon
                    startColor={configuration?.gradientStyle?.spinnerColor}
                    stopColor={configuration?.gradientStyle?.stopColor}
                  />
                </div>
              ) : (
                <></>
              )} */}
                {item.walletConnector?.name ==
                primaryWallet?.connector?.name ? (
                  <div className="bw-text-[10px] bw-flex bw-items-center bw-gap-x-1 bw-mt-[-2px] bw-font-medium bw-absolute bw-top-1 bw-right-2">
                    <div className="bw-w-[10px] bw-rounded-[50%] bw-bg-text-greentext bw-h-[10px]"></div>{" "}
                    Connected
                  </div>
                ) : item.isInstalledOnBrowser ? (
                  <div className="bw-absolute bw-top-1 bw-right-0">
                    <InstalledIcon
                      startColor={configuration?.gradientStyle?.spinnerColor}
                      stopColor={configuration?.gradientStyle?.stopColor}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <img
                  src={walletIcons[item.name?.toLowerCase()]}
                  width={40}
                  height={40}
                  className="bw-max-h-[40px]"
                  alt="img"
                />
                <p className="bw-text-xs bw-w-max ">{item.name}</p>
              </div>
            </div>
          );
        })}
    </>
  ) : (
    <div className="bw-w-full bw-flex bw-justify-center bw-mt-2 bw-text-text-primary">
      <CircularProgress color="inherit" size={14} />
    </div>
  );
};

export default Main;
