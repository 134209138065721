export default function AddWallet({ fill }) {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2138 6.45896H6.18375V10.709H4.59375V6.45896H0.58375V4.97895H4.59375V0.748954H6.18375V4.97895H10.2138V6.45896Z"
        fill={fill}
      />
    </svg>
  );
}
