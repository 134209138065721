import React, { useState, useEffect } from "react";
import { useConnect, useAccount } from "wagmi";
import getIsInstalled from "./getisInstalled";
import images from "../../images";
import SolanaWallet from "../SolanaWallet";
import useStore from "../../zustand/store";
import BackButton from "../Svg/backButton";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import InstalledIcon from "../InstalledIcon";
import CloseButton from "../Svg/closeButton";
import TronWallet from "../TronWallet";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import useWalletDetails from "../../hooks/useWalletDetails";
import ConnectorLoader from "./connectorLoader";
import { disconnect } from "@wagmi/core";
import WalletTab from "./walletTab";
import WalletIcon from "../Svg/walletIcon";
import CustomWallet from "./customWallet";
import styles from "./SelectWallet.module.css";
import CosmosWallet from "../CosmosWallet";
import { useAccount as useCosmosAccount } from "graz";
export default function SelectWallet({
  handleShowWallet,
  connectWalletField,
  portfolio,
}) {
  console.log(connectWalletField, "connect");
  const { connectAsync, data, connectors, isSuccess, isLoading, reset, error } =
    useConnect();
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  const { primaryWallet, isFullyConnected } = useDynamicContext();
  const [selectedWallet, setSelectedWallet] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [selectedConnector, setSelectedConnector] = useState();
  const [isSolConnecting, setIsSolanaConnecting] = useState(false);
  const [isTronConnecting, setIsTronConnecting] = useState(false);
  const [isCosmosonnecting, setIsCosmosConnecting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { connected, address } = useWallet();
  const { handleEVMDisable } = useWalletDetails({ fromChain, toChain });
  const [selectedWalletTab, setSelectedWalletTab] = useState("All");
  const isEvmDisable = handleEVMDisable(connectWalletField);
  const { isConnected, connector } = useAccount();
  const { data: accounts, isConnected: isCosmosConnected } = useCosmosAccount();
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  async function handleConnect(connector) {
    setErrorMsg("");
    try {
      let result = await connectAsync({ connector });
      setErrorMsg((prev) => {
        if (prev?.length && result) {
          handleShowWallet();
        }
        return "";
      });
    } catch (err) {
      console.log(err, "error");
      setErrorMsg(err.details || err.message || "");
    }
  }
  useEffect(() => {
    let timeout;
    if (data && isSuccess)
      timeout = setTimeout(() => {
        handleShowWallet();
      }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccess, data]);

  const { metamaskicon, injectedicon, coinbaseicon, walletconnecticon } =
    images;
  const walletIcons = {
    injected: injectedicon,
    metaMask: metamaskicon,
    coinbaseWallet: coinbaseicon,
    walletConnect: walletconnecticon,
  };

  const configuration = useStore((state) => state.configuration);
  const chainObj = { from: fromChain, to: toChain };
  function handleSolConnecting(val, wallet) {
    setErrorMsg("");
    if (wallet) {
      setSelectedWallet(wallet);
    }
    setIsSolanaConnecting(val);
    setShowLoading(false);
  }
  function handleOtherWalletConnecting() {
    setShowLoading(true);
    setIsSolanaConnecting(false);
    setIsTronConnecting(false);
  }
  function handleTronConnection(val, wallet) {
    setErrorMsg("");
    if (wallet) setSelectedWallet(wallet);
    setIsTronConnecting(val);
    setShowLoading(false);
  }
  function handleCosmosConnection(val, wallet) {
    setErrorMsg("");
    if (wallet) setSelectedWallet(wallet);
    setIsCosmosConnecting(val);
    setShowLoading(false);
  }
  useEffect(() => {
    let timeout;
    if (isFullyConnected && primaryWallet !== null && isSolConnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleSolConnecting(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isFullyConnected, primaryWallet, isSolConnecting]);

  useEffect(() => {
    let timeout;
    if (connected && address && isTronConnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleTronConnection(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [connected, address, isTronConnecting]);
  useEffect(() => {
    let timeout;
    if (isCosmosConnected && accounts?.bech32Address && isCosmosonnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleCosmosConnection(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCosmosConnected, accounts?.bech32Address, isCosmosonnecting]);

  // useEffect(() => {
  // !portfolio &&
  //   setShowLoading(
  //     (!isLoading &&
  //       !data &&
  //       chainObj[connectWalletField]?.networkType == "evm") ||
  //       (!isSolConnecting &&
  //         chainObj[connectWalletField]?.networkType == "sol") ||
  //       (!isTronConnecting &&
  //         chainObj[connectWalletField]?.networkType == "tron")
  //   );
  // }, [isLoading, data, connectWalletField, isSolConnecting, portfolio]);
  function handleWalletTab(wallet) {
    setSelectedWalletTab(wallet);
  }
  console.log(connectWalletField, "connectWalletField123");
  return showLoading ? (
    <div className="">
      <div className="bw-flex bw-relative bw-justify-center bw-mb-2">
        <button
          onClick={handleShowWallet}
          className="bw-absolute bw-left-0 bw-top-[25%]"
        >
          <BackButton fill={"var(--tw-text-secondary)"} />
        </button>
        <div className="bw-text-lg bw-font-medium bw-text-text-secondary">
          Connect Wallet
        </div>
        <button
          onClick={handleShowWallet}
          className="bw-absolute bw-right-0 bw-top-[25%]"
        >
          <CloseButton fill={"var(--tw-text-secondary)"} />
        </button>
      </div>
      <WalletTab
        selectedWalletTab={selectedWalletTab}
        handleWalletTab={handleWalletTab}
      />
      <div
        className={`${
          portfolio ? "bw-h-[630px]" : "bw-h-[400px]"
        } bw-overflow-y-auto  bw-mt-4  `}
      >
        <div
          className={` bw-mx-auto bw-px-2  bw-w-full bw-flex bw-justify-between bw-flex-wrap `}
        >
          {selectedWalletTab == "All" || selectedWalletTab == "EVM" ? (
            connectors.map((item, i) => {
              return (
                <div
                  style={
                    connector?.id == item?.id
                      ? {
                          ...configuration?.gradientStyle,
                        }
                      : {}
                  }
                  className={`bw-w-[47%] bw-mb-4  bw-rounded-md bw-p-[1px] ${
                    !configuration.gradientStyle && connector?.id == item?.id
                      ? styles.gradientbutton
                      : "bw-bg-border-primary"
                  } `}
                >
                  <div
                    key={i}
                    onClick={() => {
                      if (connector?.id == item?.id) {
                        if (connectWalletField == "to") {
                          setToDisconnect(false);
                          handleShowWallet();
                        } else {
                          disconnect(item.id);
                        }
                      } else {
                        setSelectedWallet(item.id);
                        setSelectedConnector(item);
                        handleConnect(item);
                        setShowLoading(false);
                        setToDisconnect(false);
                      }
                    }}
                    style={{ cursor: "pointer" }}
                    className={`bw-w-full bw-bg-background-container hover:bw-shadow-sm bw-font-medium hover:bw-font-bold  hover:bw-text-text-primary  bw-flex bw-gap-x-3  bw-px-3 bw-justify-start bw-items-center bw-h-[56px] ${
                      connector?.id == item?.id
                        ? " bw-text-text-primary"
                        : "bw-text-text-secondary"
                    } bw-relative  bw-rounded-md 
              ${
                ""
                // !isConnected && item.ready
                //   ? ""
                //   : "bw-opacity-50 bw-pointer-events-none"
              } 
              `}
                  >
                    {/* {getIsInstalled(item?.id?.toLowerCase()) ? (
                  <div className="bw-absolute bw-top-1 bw-right-2">
                    <InstalledIcon
                      startColor={configuration?.gradientStyle?.spinnerColor}
                      stopColor={configuration?.gradientStyle?.stopColor}
                    />
                  </div>
                ) : (
                  <></>
                )} */}
                    {connector?.id == item?.id ? (
                      <div className="bw-text-[10px] bw-flex bw-items-center bw-gap-x-1 bw-mt-[-2px] bw-font-medium bw-absolute bw-top-1 bw-right-2">
                        <div className="bw-w-[10px] bw-rounded-[50%] bw-bg-text-greentext bw-h-[10px]"></div>{" "}
                        Connected
                      </div>
                    ) : getIsInstalled(item?.id?.toLowerCase()) ? (
                      <div className="bw-absolute bw-top-1 bw-right-0">
                        <InstalledIcon
                          startColor={
                            configuration?.gradientStyle?.spinnerColor
                          }
                          stopColor={configuration?.gradientStyle?.stopColor}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <img
                      src={walletIcons[item.id]}
                      width={40}
                      height={40}
                      alt="img"
                    />
                    <p className="bw-text-xs bw-w-max ">{item.name}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}

          {selectedWalletTab == "All" || selectedWalletTab == "SVM" ? (
            <SolanaWallet
              handleShowWallet={handleShowWallet}
              fromChain={fromChain}
              toChain={toChain}
              connectWalletField={connectWalletField}
              handleSolConnecting={handleSolConnecting}
              portfolio={portfolio}
              selectedWalletTab={selectedWalletTab}
            />
          ) : (
            <></>
          )}
          {selectedWalletTab == "All" || selectedWalletTab == "Cosmos" ? (
            <CosmosWallet
              selectedWalletTab={selectedWalletTab}
              handleCosmosConnection={handleCosmosConnection}
              setErrorMsg={setErrorMsg}
            />
          ) : (
            <></>
          )}
          {selectedWalletTab == "All" || selectedWalletTab == "Other" ? (
            <TronWallet
              handleTronConnection={handleTronConnection}
              connectWalletField={connectWalletField}
              fromChain={fromChain}
              toChain={toChain}
              selectedWalletTab={selectedWalletTab}
              handleShowWallet={handleShowWallet}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* <p className="bw-bg-background-container bw-text-sm bw-font-normal bw-text-center bw-mt-2">
        {errorMsg}
      </p> */}
      {connectWalletField == "to" ? (
        <CustomWallet
          portfolio={portfolio}
          handleShowWallet={handleShowWallet}
        />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <ConnectorLoader
      selectedWallet={selectedWallet}
      selectedConnector={selectedConnector}
      handleConnect={handleConnect}
      handleOtherWalletConnecting={handleOtherWalletConnecting}
      isSolConnecting={isSolConnecting}
      isTronConnecting={isTronConnecting}
      isCosmosonnecting={isCosmosonnecting}
      isSuccess={isSuccess}
      data={data}
      evmError={errorMsg}
    />
  );
}
