import React, { useEffect, useRef } from "react";
import { colorify, flatten, replaceColor, getColors } from "lottie-colorify";
import Lottie from "lottie-web";
import spinnerNew from "./spinnernew.json";
import useStore from "../zustand/store";
function Spinner() {
  const container = useRef(null);
  const configuration = useStore((state) => state.configuration);
  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: container.current,
      animationData: colorify(
        [
          configuration?.gradientStyle?.spinnerColor
            ? configuration?.gradientStyle?.spinnerColor
            : "#2CFFE4",
        ],
        spinnerNew
      ),
    });
    return () => animation.destroy();
  }, []);
  return <div style={{ width: "100%", height: "100%" }} ref={container}></div>;
}

export default Spinner;
