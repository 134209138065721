import React from "react";

function CloseRed() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1794 14.2695H10.5846C10.4039 14.2695 10.2587 14.2243 10.149 14.134C10.0457 14.0436 9.96177 13.9403 9.89723 13.8242L6.6538 8.45072C6.60216 8.61209 6.54084 8.75086 6.46984 8.86704L3.36196 13.8242C3.2845 13.9339 3.19414 14.0372 3.09086 14.134C2.99405 14.2243 2.86495 14.2695 2.70359 14.2695H0.273438L4.92074 7.06621L0.457393 0.269531H3.05214C3.23287 0.269531 3.36196 0.29535 3.43941 0.346986C3.52332 0.392168 3.60078 0.469624 3.67178 0.579352L6.85712 5.71075C6.92166 5.54938 6.99912 5.38802 7.08948 5.22666L10.0134 0.627761C10.0844 0.505124 10.1619 0.41476 10.2458 0.356669C10.3297 0.298577 10.4362 0.269531 10.5653 0.269531H13.0535L8.55145 6.95971L13.1794 14.2695Z"
        fill="#FD5868"
      />
    </svg>
  );
}

export default CloseRed;
