import truncate from "../../../utils/truncate";
import Refetch from "../../Svg/refresh";
import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";
export default function NetWorthComp({ netWorth, portfolio }) {
  const queryClient = useQueryClient();
  return netWorth ? (
    <div className="bw-min-w-[150px] bw-mt-1 bw-flex bw-items-center bw-flex-col bw-justify-center bw-gap-x-2 bw-w-1/2 bw-text-center bw-text-sm bw-bottom-[-14%] bw-text-text-primary bw-font-bold bw-left-[8%] bw-z-0">
      <p className="bw-text-text-secondary">Net Worth </p>
      <div className="bw-flex bw-items-center bw-gap-x-2">
        <span className="bw-text-lg bw-text-text-primary">
          ${truncate(netWorth, 2)}
        </span>
        <button
          onClick={() => {
            portfolio.refetch();
          }}
        >
          {!portfolio.isLoading && !portfolio.isFetching ? (
            <Refetch fill={"var(--tw-text-primary)"} />
          ) : (
            <CircularProgress size={16} thickness={3} color="inherit" />
          )}
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}
