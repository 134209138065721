export default function ClockIcon({ fill }) {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="6.26005"
        cy="7.03456"
        rx="6.04911"
        ry="6.09706"
        fill={fill}
        fill-opacity="1"
      />
      <path
        d="M6.09375 3.85254V7.6632L9.49637 6.13894"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
