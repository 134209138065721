import BackButton from "../Svg/backButton";
import Hamburger from "../Svg/hamburger";
import useStore from "../../zustand/store";
export default function Header() {
  const setRoute = useStore((state) => state.setRoute);
  return (
    <div className="bw-w-full bw-flex bw-items-center bw-justify-between">
      <div
        onClick={() => {
          setRoute("");
        }}
        className="bw-cursor-pointer"
      >
        <BackButton fill={"var(--tw-text-secondary)"} />
      </div>
      <p className="bw-text-lg bw-font-bold bw-text-text-primary">History</p>
      <div className="bw-w-[36px] bw-cursor-pointer bw-h-[36px] bw-p-1 bw-flex bw-justify-center bw-place-items-center bw-border bw-border-border-primary bw-rounded-[50%]">
        <Hamburger fill={"var(--tw-text-secondary)"} />
      </div>
    </div>
  );
}
