import React, { useState, useEffect } from "react";
import TransactionDetailStep from "./TransactionDetailStep";
import StatusComp from "./StatusComp";
import { useMutation } from "react-query";
import controllers from "../../Actions/Controllers";

function TxnExecutionComp({ details, chainData }) {
  const [stepData, setStepData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [allSteps, setAllSteps] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const nextTx = useMutation(
    "nexttx",
    async ({ id, routeId }) => {
      let res = await controllers.fetchNextTx(id, routeId);
      return await res.json();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data, variables) => {
        setDisableButton(false);
      },
      onError: (err) => {
        // setErrorMessage(err.details);
      },
    }
  );

  console.log(nextTx, "nexttx");
  async function callNextTx(id, routeId) {
    try {
      await nextTx.mutateAsync({
        id,
        routeId,
      });
    } catch (err) {
      setDisableButton(false);
    }
  }
  useEffect(() => {
    if (details?.currentStepStatus == "not-started") {
      let currentStepData = details?.steps[details?.currentStepIndex];
      setStepData(currentStepData);
      setDisableButton(true);
      callNextTx(currentStepData?.stepId, details?.routeId);
    }
    setCurrentStep(details?.currentStepIndex);
    setAllSteps(details?.steps);
  }, [details]);
  return (
    <>
      <TransactionDetailStep details={details} />
      <StatusComp
        details={details}
        chainData={chainData}
        disableButton={disableButton}
        setDisableButton={setDisableButton}
        nextTx={nextTx}
      />
    </>
  );
}

export default TxnExecutionComp;
