import { useMemo } from "react";
import truncate from "../../../utils/truncate";
import { useState } from "react";
export default function LegendComp(props) {
  const { payload, selectChain, selectChainArray, activeIndex } = props;
  const memoVal = useMemo(
    () =>
      payload.filter(
        (item, i) =>
          item.type === "rect" && typeof item.payload.balance == "undefined"
      ),
    [payload]
  );
  return (
    <div>
      {memoVal?.map((entry, index, arr) => {
        return entry.value ? (
          <div
            className={` ${
              typeof activeIndex !== "undefined" && activeIndex !== index
                ? "bw-opacity-50"
                : ""
            } ${
              selectChainArray.length <= 1 || selectChainArray.length > 4
                ? ""
                : typeof selectChain[entry.payload.name] !== "undefined"
                ? ""
                : "bw-opacity-50"
            } bw-flex bw-items-start bw-mb-1 bw-justify-end bw-gap-x-2`}
          >
            <div className="bw-text-right">
              <div className="bw-flex bw-items-center bw-gap-x-2">
                <p
                  className={`bw-text-xs bw-leading-none bw-font-bold  ${
                    activeIndex !== index
                      ? "bw-text-text-secondary"
                      : "bw-text-text-primary"
                  }`}
                  key={`item-${index}`}
                >
                  {entry.value}
                </p>
                <span
                  className={`bw-text-xs bw-leading-none  bw-font-bold ${
                    activeIndex !== index
                      ? "bw-text-text-secondary"
                      : "bw-text-text-primary"
                  }`}
                >
                  {truncate(entry.payload.percentShare * 100, 4)}%
                </span>
              </div>
              <span
                className={`bw-text-xs bw-font-bold  ${
                  activeIndex !== index
                    ? "bw-text-text-secondary"
                    : "bw-text-text-primary"
                }`}
              >
                ${truncate(entry.payload.value, 4)}
              </span>
            </div>
            <div
              style={{ background: entry.color, width: "10px", height: "10px" }}
            ></div>
          </div>
        ) : (
          <></>
        );
      })}
    </div>
  );
}
