import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { Connection, PublicKey } from "@solana/web3.js";
import { useEffect, useState } from "react";
export default function useSolBalance(fromChain) {
  console.log(fromChain, "fromchain");
  const { primaryWallet } = useDynamicContext();
  const [balance, setBalance] = useState(0);
  const getBalance = async () => {
    if (!primaryWallet) return;

    const connection = await primaryWallet.connector.getPublicClient();

    if (!connection) return;

    const publicKey = new PublicKey(primaryWallet.address);
    const balanceSol = await connection.getBalance(publicKey);

    // console.log("Balance in lamports:", balance);
    const balanceSolVal = balanceSol / 1000000000; // Convert lamports to SOL
    setBalance(balanceSolVal);
  };
  useEffect(() => {
    fromChain?.networkType === "sol" && getBalance();
  }, [fromChain?.networkType, primaryWallet?.address]);
  return { balanceSol: balance, getBalanceSol: getBalance };
}
