import Fail from "../Svg/fail";
import InProg from "../Svg/inProg";
import Success from "../Svg/success";
import Date from "../Svg/date";
import Modal from "@mui/material/Modal";
import React from "react";
import useStore from "../../zustand/store";
import Box from "@mui/material/Box";
import DateRangePicker from "../DatePicker";
export default function TransactionTab({ value, handleValue, handleDate }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const configuration = useStore((state) => state.configuration);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "var(--tw-bg-container)",
  };
  function handleSubmit(start, end, nights) {
    handleClose();
    handleDate(start, end);
  }
  return (
    <>
      <div className="bw-flex bw-w-[98%] bw-items-center bw-gap-x-4 bw-mb-3 bw-mt-2">
        <div
          onClick={() => {
            handleValue("all");
            handleDate();
          }}
          className={`${
            value === "all"
              ? "bw-text-text-primary bw-font-bold"
              : "bw-text-text-secondary bw-font-normal"
          } bw-text-xs bw-cursor-pointer bw-flex bw-flex-col  bw-flex-1  bw-items-center bw-justify-center   bw-w-[45px] `}
        >
          <p className="min-w-[15px]">All</p>
          <div
            className={`bw-w-full bw-mt-1 bw-h-[1px] ${
              value == "all" ? "bw-bg-text-primary" : "bw-bg-border-primary"
            }`}
          ></div>
        </div>
        <div
          onClick={() => {
            handleValue("in-progress");
            handleDate();
          }}
          className={`${
            value === "in-progress"
              ? "bw-text-text-primary bw-font-bold"
              : "bw-text-text-secondary bw-font-normal"
          } bw-text-xs bw-cursor-pointer   bw-flex bw-flex-col   bw-justify-center  bw-items-center bw-gap-x-1`}
        >
          <div className="bw-flex bw-gap-x-1  bw-flex-1  bw-justify-center  bw-items-center">
            <InProg
              fill={"var(--tw-background-container)"}
              stroke={"var(--tw-text-secondary)"}
            />
            <p className="min-w-[50px]">In Progress</p>
          </div>
          <div
            className={`bw-w-full bw-mt-1 bw-h-[1px] ${
              value == "in-progress"
                ? "bw-bg-text-primary"
                : "bw-bg-border-primary"
            }`}
          ></div>
        </div>
        <div
          onClick={() => {
            handleValue("success");
            handleDate();
          }}
          className={`${
            value === "success"
              ? "bw-text-text-primary bw-font-bold"
              : "bw-text-text-secondary bw-font-normal"
          } bw-text-xs bw-cursor-pointer  bw-flex  bw-flex-col  bw-flex-1   bw-justify-center    bw-items-center bw-gap-x-1`}
        >
          <div className="bw-flex bw-gap-x-1   bw-justify-center  bw-items-center">
            <Success
              fill={"var(--tw-background-container)"}
              spinnerColor={configuration?.gradientStyle?.spinnerColor}
              stopColor={configuration?.gradientStyle?.stopColor}
            />
            <p className="min-w-[60px]"> Completed</p>
          </div>
          <div
            className={`bw-w-full bw-mt-1 bw-h-[1px] ${
              value == "success" ? "bw-bg-text-primary" : "bw-bg-border-primary"
            }`}
          ></div>
        </div>
        <div
          onClick={() => {
            handleValue("failed");
            handleDate();
          }}
          className={`${
            value === "failed"
              ? "bw-text-text-primary bw-font-bold"
              : "bw-text-text-secondary bw-font-normal"
          } bw-text-xs bw-cursor-pointer  bw-flex  bw-flex-col   bw-justify-center      bw-flex-1 bw-items-center bw-gap-x-1`}
        >
          <div className="bw-flex bw-gap-x-1  bw-justify-center  bw-items-center">
            <Fail
              fill={"var(--tw-background-container)"}
              stroke={"var(--tw-text-secondary)"}
            />
            <p className="min-w-[60px]">Failed</p>
          </div>
          <div
            className={`bw-w-full bw-mt-1 bw-h-[1px] ${
              value == "failed" ? "bw-bg-text-primary" : "bw-bg-border-primary"
            }`}
          ></div>
        </div>
        <div
          onClick={() => {
            handleValue("date");
          }}
          className={`${
            value == "date"
              ? "bw-text-text-primary bw-font-bold"
              : "bw-text-text-secondary bw-font-normal"
          } bw-text-xs bw-flex bw-cursor-pointer   bw-flex-col   bw-justify-center  bw-flex-1 bw-items-center bw-gap-x-1`}
        >
          <div
            onClick={handleOpen}
            className="bw-flex bw-gap-x-1  bw-justify-center  bw-items-center"
          >
            <Date />
            Date
          </div>
          <div
            className={`bw-w-full bw-mt-1 bw-h-[1px] ${
              value == "date" ? "bw-bg-text-primary" : "bw-bg-border-primary"
            }`}
          ></div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <DateRangePicker
                handleSubmit={handleSubmit}
                handleClose={handleClose}
              />
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}
