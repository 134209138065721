export default function fixChar(x, decimal) {
  let tempX = x.toString();
  let split = tempX.split(".");
  let value =
    split[0]?.length > decimal || split[0]?.length + 1 === decimal
      ? split[0]
      : split[1]?.length
      ? split.join(".").slice(0, decimal)
      : split[0];
  return value ? Number(value) : 0;
}
