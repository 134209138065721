export default function SortDown({ isSelected, fill }) {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 5 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.69535 4.04883L0.476187 0.20513L4.91451 0.20513L2.69535 4.04883Z"
        fill={fill}
        fill-opacity={isSelected ? "1" : "0.2"}
      />
    </svg>
  );
}
