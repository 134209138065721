import truncate from "../../utils/truncate";
import { CircularProgress } from "@mui/material";
import { useQuery, useInfiniteQuery } from "react-query";
import controllers from "../../Actions/Controllers";
import useWalletDetails from "../../hooks/useWalletDetails";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useAccount } from "wagmi";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import ShareIcon from "../Svg/shareicon";
import useStore from "../../zustand/store";
import images from "../../images";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import getAddressType from "../../utils/getAddressType";
export default function Token({
  selectChainState,
  value,
  handleClosePopup,
  showExchangeList,
  setCoinData,
  setChainData,
}) {
  // console.log("token123");
  const [searchCustomCoin, setSearchCustomCoin] = useState("");
  const { ref, inView } = useInView();
  const { getWallet } = useWalletDetails({});
  const { connected, address: tronAddress } = useWallet();
  const { isConnected, address } = useAccount();
  const { primaryWallet, isFullyConnected } = useDynamicContext();
  const fromWalletAddress = getWallet(selectChainState);
  const fromChain = useStore((state) => state.fromChain);
  const fromCoin = useStore((state) => state.fromCoin);
  const toChain = useStore((state) => state.toChain);
  const toCoin = useStore((state) => state.toCoin);
  const coinData = showExchangeList == "from" ? fromCoin : toCoin;
  const { defaultCoin } = images;
  const isWalletConnected =
    (selectChainState?.networkType === "evm" && address) ||
    (selectChainState?.networkType === "sol" && primaryWallet?.address) ||
    (selectChainState?.networkType === "tron" && tronAddress);
  const toDisconnect = useStore((state) => state.toDisconnect);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const customWallet = useStore((state) => state.customWallet);
  const setCustomWallet = useStore((state) => state.setCustomWallet);
  function handleSort(val) {
    return (
      truncate(
        fetchBalance.data?.data?.[selectChainState?.networkType]?.[
          selectChainState.chainId
        ]?.[val.address]?.balance /
          Math.pow(
            10,
            fetchBalance.data?.data?.[selectChainState?.networkType]?.[
              selectChainState.chainId
            ]?.[val.address]?.asset?.decimals || 0
          ),
        6
      ) || ""
    );
  }

  const fetchTokens = useQuery(
    ["tokens", selectChainState, searchCustomCoin],
    async function () {
      let res = await controllers.fetchTokens(
        selectChainState.chainId,
        searchCustomCoin
      );
      return await res.json();
    },
    {
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: selectChainState?.chainId ? true : false,
    }
  );
  // console.log(searchCustomCoin, value, "searchCustomCoin");
  useEffect(() => {
    if (value.length > 40 && getAddressType(value)) {
      setSearchCustomCoin(value);
    } else {
      setSearchCustomCoin("");
    }
  }, [value]);
  useEffect(() => {
    inView && fetchTokens.hasNextPage && fetchTokens.fetchNextPage();
  }, [inView]);
  const fetchBalance = useQuery(
    ["balance", fromWalletAddress?.address, selectChainState],
    async () => {
      let res = await controllers.fetchBalance(
        fromWalletAddress.address,
        selectChainState.chainId
      );
      return await res.json();
    },
    {
      enabled:
        fromWalletAddress?.address?.length && selectChainState.chainId
          ? true
          : false,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <div className=" bw-h-[200px] bw-pr-2 md:bw-h-[380px] bw-mt-2 bw-overflow-y-auto">
      {fetchTokens?.isSuccess &&
        fetchTokens.data?.data
          ?.filter((item) => {
            return (
              item.name?.toLowerCase().includes(value?.toLowerCase()) ||
              item.symbol?.toLowerCase().includes(value?.toLowerCase()) ||
              item.address?.toLowerCase()?.includes(value?.toLowerCase())
            );
          })
          .sort((a, b) => {
            if (isWalletConnected)
              return (
                truncate(handleSort(b) * b.lastPrice, 6) -
                truncate(handleSort(a) * a.lastPrice, 6)
              );
            else return;
          })
          .map((item, idx, array) => (
            <div
              key={idx}
              className={`bw-py-2
                  ${
                    showExchangeList == "from" && toCoin._id !== item._id
                      ? ""
                      : showExchangeList == "to" && fromCoin._id !== item._id
                      ? ""
                      : ""
                    // "bw-pointer-events-none bw-opacity-60"
                  }
                  bw-flex bw-items-center  bw-justify-between bw-cursor-pointer bw-border-b bw-border-border-primary
                   
                  }`}
              onClick={async () => {
                let newObj = {
                  ...coinData,
                  coin: item.symbol,
                  ...item,
                  availBal:
                    fetchBalance.data?.data?.[selectChainState?.networkType]?.[
                      selectChainState.chainId
                    ]?.[item.address.toLowerCase()]?.balance /
                    Math.pow(
                      10,
                      fetchBalance.data?.data?.[
                        selectChainState?.networkType
                      ]?.[selectChainState?.chainId]?.[
                        item.address.toLowerCase()
                      ]?.asset?.decimals || 0
                    ),
                };

                setCoinData(newObj);
                setChainData(selectChainState);
                handleClosePopup(selectChainState, newObj);
                if (selectChainState?.networkType === toChain?.networkType) {
                  setToDisconnect(false);
                } else if (
                  selectChainState?.networkType === fromChain?.networkType
                ) {
                  setToDisconnect(false);
                  setCustomWallet("");
                } else {
                  setToDisconnect(true);
                  setCustomWallet("");
                }

                // setCustomWallet("");
                // if (showExchangeList == "from" && toCoin.name !== item.name) {
                //   handleClosePopup(selectChainState, newObj);
                //   setCoinData(newObj);
                // } else if (
                //   showExchangeList == "to" &&
                //   fromCoin.name !== item.name
                // ) {
                //   handleClosePopup(selectChainState, newObj);
                //   setCoinData(newObj);
                // } else if (toChain.name !== fromChain.name) {
                //   handleClosePopup(selectChainState, newObj);
                //   setCoinData(newObj);
                // }
              }}
            >
              <div className="bw-flex bw-items-center bw-justify-center bw-w-max bw-gap-x-2">
                <div className="bw-relative bw-rounded-[50%] bw-bg-background-coin bw-w-[30px] bw-h-[30px]">
                  {item.image ? (
                    <img
                      src={item.image}
                      className="bw-rounded-[50%]"
                      width={30}
                      height={30}
                      alt="img"
                    />
                  ) : (
                    <img
                      src={defaultCoin}
                      className="bw-rounded-[50%]"
                      width={30}
                      height={30}
                      alt="img"
                    />
                  )}
                  {selectChainState?.image ? (
                    <img
                      src={selectChainState.image}
                      className={
                        "bw-absolute bw-bg-background-coin bw-right-0 bw-bottom-[-4px] bw-border bw-border-border-primary bw-rounded-[50%]"
                      }
                      width={16}
                      height={16}
                      alt="img"
                    />
                  ) : (
                    <img
                      src={defaultCoin}
                      className={
                        "bw-absolute bw-bg-background-coin bw-right-0 bw-bottom-[-4px] bw-border bw-border-border-primary bw-rounded-[50%]"
                      }
                      width={16}
                      height={16}
                      alt="img"
                    />
                  )}
                </div>
                <div>
                  <p className="bw-text-sm bw-font-normal bw-text-text-secondary">
                    <span className="bw-font-bold bw-text-text-primary">
                      {item.symbol || ""}{" "}
                    </span>

                    {item.name}
                  </p>
                  <div className="bw-flex bw-items-center bw-gap-x-2">
                    <p className="bw-text-xs bw-font-normal  bw-text-text-secondary">
                      {selectChainState.name}
                    </p>

                    <div className="bw-bg-background-networkcard  bw-flex bw-items-center bw-gap-x-2 bw-px-2">
                      <p className="bw-text-xs bw-font-normal bw-text-text-secondary">
                        {item?.address
                          ? item.address.substring(0, 6) +
                            "..." +
                            item.address.substring(
                              item.address.length - 6,
                              item.address.length
                            )
                          : ""}
                      </p>
                      <a
                        href={selectChainState.explorer?.token?.replace(
                          "{tokenAddress}",
                          item.address
                        )}
                        target="_blank"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="hover:bw-opacity-70"
                      >
                        <ShareIcon fill={"var(--tw-text-secondary)"} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="bw-text-sm bw-text-right bw-font-medium bw-text-text-primary">
                  {showExchangeList === "to" &&
                  (customWallet?.length > 30 || toDisconnect) ? (
                    <></>
                  ) : !fetchBalance.isFetching ? (
                    isWalletConnected ? (
                      <>
                        {handleSort(item)}
                        {handleSort(item) && (
                          <p className="bw-text-text-secondary bw-font-normal bw-text-xs">
                            $
                            {truncate(handleSort(item) * item.lastPrice, 6) ||
                              ""}
                          </p>
                        )}
                      </>
                    ) : (
                      <></>
                    )
                  ) : (
                    <CircularProgress size={12} thickness={4} color="inherit" />
                  )}
                </p>
              </div>
            </div>
          ))}
    </div>
  );
}
