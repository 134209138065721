import React from "react";
import BackButton from "../Svg/backButton";

function Header({ handleBack }) {
  return (
    <div className="bw-flex bw-z-20  bw-relative bw-justify-center bw-mb-5">
      <button
        onClick={() => {
          handleBack();
        }}
        className="bw-absolute bw-left-0 bw-top-[25%]"
      >
        <BackButton fill={"var(--tw-text-secondary)"} />
      </button>
    </div>
  );
}

export default Header;
