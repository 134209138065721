import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useState } from "react";
import images from "../../../images";
import SortUp from "../../Svg/sortUp";
import SortDown from "../../Svg/sortDown";
import MaxOutput from "./maxOutput";
import LowestGas from "./lowestGas";
import Fastest from "./fastest";
import Maxop from "../../Svg/maxop";
import FastestSvg from "../../Svg/fastest";
import LowgasSvg from "../../Svg/lowgas";
import useStore from "../../../zustand/store";
const Label1 = React.memo(function ({ maxOpSort, value }) {
  const { maxop } = images;
  const configuration = useStore((state) => state.configuration);
  return (
    <div className="bw-flex bw-items-center bw-gap-x-2">
      <div className="bw-w-[16px] bw-h-[16px]">
        <Maxop
          fill={"var(--tw-background-container)"}
          spinnerColor={configuration?.gradientStyle?.spinnerColor}
          stopColor={configuration?.gradientStyle?.stopColor}
        />
      </div>
      <span
        className={`bw-text-xs ${
          value == 1
            ? "bw-text-text-primary bw-font-medium"
            : "bw-text-text-secondary bw-font-medium"
        }`}
      >
        Max Output
      </span>
      <div className="bw-flex bw-gap-y-[2px] bw-items-center bw-flex-col bw-justify-center">
        <SortUp
          isSelected={maxOpSort == "asc" ? true : false}
          fill={"var(--tw-text-primary)"}
        />
        <SortDown
          fill={"var(--tw-text-primary)"}
          isSelected={maxOpSort == "desc" ? true : false}
        />
      </div>
    </div>
  );
});
const Label2 = React.memo(function ({ lowestGasSort, value }) {
  const { fast } = images;
  return (
    <div className="bw-flex bw-items-center bw-gap-x-2">
      <div className="bw-w-[16px] bw-h-[16px]">
        <LowgasSvg fill={"var(--tw-background-container)"} />
      </div>
      <span
        className={`bw-text-xs ${
          value == 2
            ? "bw-text-text-primary bw-font-medium"
            : "bw-text-text-secondary bw-font-medium"
        }`}
      >
        Lowest Gas
      </span>
      <div className="bw-flex bw-gap-y-[2px] bw-items-center bw-flex-col bw-justify-center">
        <SortUp
          fill={"var(--tw-text-primary)"}
          isSelected={lowestGasSort === "asc" ? true : false}
        />
        <SortDown
          fill={"var(--tw-text-primary)"}
          isSelected={lowestGasSort === "desc" ? true : false}
        />
      </div>
    </div>
  );
});
const Label3 = React.memo(function ({ fastestSort, value }) {
  const { lowgas } = images;
  return (
    <div className="bw-flex bw-items-center bw-gap-x-2">
      <div className="bw-w-[16px] bw-h-[16px]">
        <FastestSvg fill={"var(--tw-background-container)"} />
      </div>
      <span
        className={`bw-text-xs ${
          value == 3
            ? "bw-text-text-primary bw-font-medium"
            : "bw-text-text-secondary bw-font-medium"
        }`}
      >
        Fastest
      </span>
      <div className="bw-flex bw-gap-y-[2px] bw-items-center bw-flex-col bw-justify-center">
        <SortUp
          fill={"var(--tw-text-primary)"}
          isSelected={fastestSort === "asc" ? true : false}
        />
        <SortDown
          fill={"var(--tw-text-primary)"}
          isSelected={fastestSort === "desc" ? true : false}
        />
      </div>
    </div>
  );
});
export default function TabComp({
  routes,
  handleShowAllRoutes,
  handleRoutesData,
  convertVal,
  fromChain,
  toChain,
}) {
  const [value, setValue] = useState("1");
  const [maxOpSort, setMaxOpSort] = useState("none");
  const [lowestGasSort, setLowestGasSort] = useState("none");
  const [fastestSort, setFastestSort] = useState("none");
  const configuration = useStore((state) => state.configuration);
  function handleValue(e, val) {
    setValue(val);
  }

  return (
    <TabContext value={value}>
      <Box
        sx={(theme) => ({
          borderBottom: 1,
          borderColor: "var(--tw-border-primary)",
          width: "max-content",
          [theme.breakpoints.down("sm")]: { width: "100%", overflowX: "auto" },

          marginX: "auto",
        })}
      >
        <TabList
          TabIndicatorProps={{
            style: { background: "var(--tw-text-secondary)" },
          }}
          sx={(theme) => ({
            width: "max-content",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              overflowX: "auto",
            },
          })}
          onChange={handleValue}
          aria-label="lab API tabs example"
        >
          <Tab
            onClick={() => {
              if (value == "1")
                setMaxOpSort((prev) => {
                  if (prev == "none") {
                    return "asc";
                  } else if (prev === "asc") {
                    return "desc";
                  } else {
                    return "none";
                  }
                });
              else return true;
            }}
            disableRipple
            sx={(theme) => ({
              textTransform: "none",
              paddingBottom: "0px",
              fontFamily: configuration?.customTheme?.fontFamily || "lato",
              width: "120px",
              paddingX: "5px",
              [theme.breakpoints.down("sm")]: {
                minWidth: "33%",
                paddingX: "auto",
              },
            })}
            label={<Label1 maxOpSort={maxOpSort} value={value} />}
            value="1"
          />
          <Tab
            onClick={() => {
              if (value == "2")
                setLowestGasSort((prev) => {
                  if (prev == "none") {
                    return "asc";
                  } else if (prev === "asc") {
                    return "desc";
                  } else {
                    return "none";
                  }
                });
              else return true;
            }}
            disableRipple
            sx={(theme) => ({
              textTransform: "none",
              paddingBottom: "0px",
              fontFamily: configuration?.customTheme?.fontFamily || "lato",
              width: "120px",
              paddingX: "5px",
              paddingLeft: "10px",
              [theme.breakpoints.down("sm")]: {
                minWidth: "33%",
                paddingX: "auto",
                paddingLeft: "auto",
              },
            })}
            label={<Label2 lowestGasSort={lowestGasSort} value={value} />}
            value="2"
          />
          <Tab
            onClick={() => {
              if (value == "3")
                setFastestSort((prev) => {
                  if (prev == "none") {
                    return "asc";
                  } else if (prev === "asc") {
                    return "desc";
                  } else {
                    return "none";
                  }
                });
              else return true;
            }}
            disableRipple
            sx={(theme) => ({
              textTransform: "none",
              paddingBottom: "0px",
              fontFamily: configuration?.customTheme?.fontFamily || "lato",
              width: "110px",
              paddingX: "5px",
              [theme.breakpoints.down("sm")]: {
                minWidth: "33%",
                paddingX: "auto",
              },
            })}
            label={<Label3 fastestSort={fastestSort} value={value} />}
            value="3"
          />
        </TabList>
      </Box>
      <TabPanel
        sx={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "10px" }}
        value="1"
      >
        <MaxOutput
          routes={routes}
          handleRoutesData={handleRoutesData}
          handleShowAllRoutes={handleShowAllRoutes}
          convertVal={convertVal}
          fromChain={fromChain}
          sort={maxOpSort}
          toChain={toChain}
        />
      </TabPanel>
      <TabPanel
        sx={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "10px" }}
        value="2"
      >
        <LowestGas
          routes={routes}
          handleRoutesData={handleRoutesData}
          handleShowAllRoutes={handleShowAllRoutes}
          convertVal={convertVal}
          fromChain={fromChain}
          sort={lowestGasSort}
          toChain={toChain}
        />
      </TabPanel>
      <TabPanel
        sx={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "10px" }}
        value="3"
      >
        <Fastest
          routes={routes}
          handleRoutesData={handleRoutesData}
          handleShowAllRoutes={handleShowAllRoutes}
          convertVal={convertVal}
          fromChain={fromChain}
          sort={fastestSort}
          toChain={toChain}
        />
      </TabPanel>
    </TabContext>
  );
}
