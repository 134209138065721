import Tooltip from "@mui/material/Tooltip";

export default function ComingSoon({ children }) {
  return (
    <Tooltip
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      className="bw-text-text-button"
      title="Coming Soon"
    >
      {children}
    </Tooltip>
  );
}
