export default function getParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const fromChain = urlParams.get("fromChain");
  const toChain = urlParams.get("toChain");
  const fromCoin = urlParams.get("fromAddress");
  const toCoin = urlParams.get("toAddress");
  const width = urlParams.get("width");
  const height = urlParams.get("height");
  const backgroundColor = urlParams.get("backgroundColor");
  return { fromChain, toChain, toChain, toCoin, backgroundColor };
}
