import ComingSoon from "../Tooltip/Comingsoon/index.jsx";
import styles from "./Navbar.module.css";
import Exchange from "../Svg/exchange.js";
import useStore from "../../zustand/store.js";
import History from "../Svg/history.js";
import Portfolio from "../Svg/portfolio.js";
export default function Navbar() {
  const configuration = useStore((state) => state.configuration);
  const setRoute = useStore((state) => state.setRoute);
  const route = useStore((state) => state.route);
  const setPersist = useStore((state) => state.setPersist);
  function handleRoute(route) {
    setRoute(route);
  }
  return (
    <div
      style={{
        boxShadow:
          (configuration &&
            configuration.customTheme &&
            configuration.customTheme?.shadow?.boxShadow) ||
          "1px 1px 7px rgba(0, 0, 0, 0.15)",
      }}
      className="bw-absolute bw-border bw-border-border-primary bw-bottom-[-60px] bw-flex bw-items-center bw-justify-between bw-px-4  md:bw-px-10 bw-bg-background-container bw-rounded-[20px] bw-left-[-0px] bw-w-full md:bw-w-[443px] bw-h-[43px]"
    >
      <button
        onClick={() => {
          if (route !== "") {
            handleRoute("");
            setPersist({});
          }
        }}
        className="bw-flex bw-items-center bw-gap-x-2"
      >
        <div className="bw-w-[17px] bw-h-[17px]">
          <Exchange
            startColor={
              route === ""
                ? configuration?.gradientStyle?.spinnerColor
                : "var(--tw-text-secondary)"
            }
            stopColor={
              route === ""
                ? configuration?.gradientStyle?.stopColor
                : "var(--tw-text-secondary)"
            }
          />
        </div>
        <p
          style={
            route === ""
              ? {
                  ...configuration.gradientStyle,
                  color: "transparent",
                }
              : { color: "var(--tw-text-secondary)" }
          }
          className={`${
            !configuration?.gradientStyle?.background
              ? `${
                  route === "" ? styles.gradienttext : "bw-text-text-secondary"
                }`
              : "bw-bg-clip-text"
          } bw-text-sm md:bw-text-sm bw-font-normal  `}
        >
          Exchange
        </p>
      </button>
      <button
        onClick={() => {
          handleRoute("portfolio");
          setPersist({});
        }}
        className="bw-flex bw-items-center bw-gap-x-1"
      >
        <Portfolio
          startColor={
            route === "portfolio"
              ? configuration?.gradientStyle?.spinnerColor
              : "var(--tw-text-secondary)"
          }
          stopColor={
            route === "portfolio"
              ? configuration?.gradientStyle?.stopColor
              : "var(--tw-text-secondary)"
          }
        />
        <p
          style={
            route === "portfolio"
              ? {
                  ...configuration.gradientStyle,
                  color: "transparent",
                }
              : { color: "var(--tw-text-secondary)" }
          }
          className={`${
            !configuration?.gradientStyle?.background
              ? `${
                  route === "portfolio"
                    ? styles.gradienttext
                    : "bw-text-text-secondary"
                }`
              : "bw-bg-clip-text"
          } bw-text-sm md:bw-text-sm bw-font-normal  `}
        >
          Portfolio
        </p>
      </button>
      {/* <ComingSoon> */}
      <button
        onClick={() => {
          handleRoute("history");
          setPersist({});
        }}
        className="bw-flex bw-items-center bw-gap-x-1"
      >
        <History
          fill={"var(--tw-text-secondary)"}
          startColor={
            route === "history" || route === "txnDetails"
              ? configuration?.gradientStyle?.spinnerColor
              : "var(--tw-text-secondary)"
          }
          stopColor={
            route === "history" || route === "txnDetails"
              ? configuration?.gradientStyle?.stopColor
              : "var(--tw-text-secondary)"
          }
        />
        <p
          style={
            route === "history" || route === "txnDetails"
              ? {
                  ...configuration.gradientStyle,
                  color: "transparent",
                }
              : { color: "var(--tw-text-secondary)" }
          }
          className={`${
            !configuration?.gradientStyle?.background
              ? `${
                  route === "history" || route === "txnDetails"
                    ? styles.gradienttext
                    : "bw-text-text-secondary"
                }`
              : "bw-bg-clip-text"
          } bw-text-sm md:bw-text-sm bw-font-normal `}
        >
          History
        </p>
      </button>
      {/* </ComingSoon> */}
    </div>
  );
}
