export default function WalletIcon({ fill }) {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6813 6.14035V5.50643C15.6813 4.34722 14.9059 3.37279 13.8599 3.09358L13.858 2.04837C13.858 1.02834 13.0489 0.198242 12.0541 0.198242H11.9587L3.51625 2.88492C3.32397 2.89632 3.14154 2.94193 2.97023 3.01055H2.94821C1.60705 3.01055 0.515625 4.13018 0.515625 5.50643V11.7024C0.515625 13.0784 1.60684 14.1982 2.94821 14.1982H13.2487C14.5899 14.1982 15.6813 13.0786 15.6813 11.7024V10.8783C16.1684 10.6849 16.5156 10.2005 16.5156 9.63303V7.38517C16.5154 6.81815 16.1684 6.33373 15.6813 6.14035ZM15.2573 9.63282C15.2573 9.6582 15.2378 9.67821 15.213 9.67821H11.8075C11.7828 9.67821 11.7635 9.6582 11.7635 9.63282V7.38517C11.7635 7.36043 11.7832 7.34021 11.8075 7.34021H15.213C15.2374 7.34021 15.2573 7.36043 15.2573 7.38517V9.63282ZM12.1319 1.49469C12.3963 1.53341 12.5999 1.76701 12.5999 2.04945L12.6016 3.01076H7.368L12.1319 1.49469ZM13.2487 12.9074H2.94842C2.29986 12.9074 1.77396 12.3677 1.77396 11.7021V5.50643C1.77396 4.84067 2.29986 4.30119 2.94842 4.30119H13.2487C13.8975 4.30119 14.4232 4.84067 14.4232 5.50643V6.04957H11.8075C11.0895 6.04957 10.5054 6.64864 10.5054 7.38517V9.63282C10.5054 10.3696 11.0895 10.9688 11.8075 10.9688H14.4232V11.7024C14.4232 12.3677 13.8975 12.9074 13.2487 12.9074Z"
        fill={fill}
      />
    </svg>
  );
}
