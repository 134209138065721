import ComingSoon from "../Tooltip/Comingsoon";
import RoundedButton from "../Button/RoundedButton";
import Hamburger from "../Svg/hamburger";
export default function Header() {
  return (
    <div className="bw-flex bw-mb-3  bw-items-center bw-justify-between">
      <p className="bw-text-2xl bw-font-normal bw-text-text-secondary">
        Exchange
      </p>
      <ComingSoon>
        <div className="bw-flex bw-items-center bw-gap-x-2">
          <RoundedButton classnames={" "}>
            <Hamburger fill={"var(--tw-text-secondary)"} />
          </RoundedButton>
        </div>
      </ComingSoon>
    </div>
  );
}
