import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect } from "react";
import { useAccount } from "wagmi";
import SelectWalletPortfolio from "../SelectWalletPortfolio";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import getWalletIcons from "../../../utils/getWalletIcons";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import Down from "../../Svg/down";
import { MenuList } from "@mui/material";
import useWalletDetails from "../../../hooks/useWalletDetails";
import WalletDetailsPortfolio from "./WalletDetailsPortfolio";
export default function ConnectWallets() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [connectedWallet, setConnectedWallet] = React.useState([]);
  const open = Boolean(anchorEl);
  const { primaryWallet, handleLogOut, isFullyConnected } = useDynamicContext();
  const { connector } = useAccount();
  const {
    address: tronAddress,
    connected,
    disconnect: tronDisconnect,
  } = useWallet();
  const { isConnected } = useAccount();
  const { getWallet } = useWalletDetails({});
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let tempArray = [];
    if (primaryWallet) {
      tempArray.push({
        networkType: primaryWallet.chain?.toLowerCase(),
        name: primaryWallet.connector.name.toLowerCase(),
      });
    }
    if (connector) {
      tempArray.push({
        networkType: "evm",
        name: connector.name.toLowerCase()?.replace(" ", ""),
      });
    }
    if (tronAddress) {
      tempArray.push({ networkType: "tron", name: "TronLink" });
    }
    setConnectedWallet(tempArray);
  }, [connector, primaryWallet, tronAddress]);
  const isNoWallet = !isConnected && !connected && !isFullyConnected;
  return (
    <>
      <div className="bw-flex bw-items-center bw-gap-x-1 bw-justify-center bw-w-max ">
        <button
          id="connect-wallet"
          onClick={handleClick}
          className=" bw-flex bw-relative bw-items-center bw-justify-center bw-w-[135px] bw-h-[35px]  bw-text-sm bw-font-medium bw-px-2 bw-rounded-2xl bw-border-[1.5px] bw-border-border-primary bw-bg-background-container bw-text-text-primary"
        >
          <div className="bw-flex bw-items-center bw-justify-center">
            {connectedWallet.length === 0 ? (
              <p className="bw-w-full ">Connect Wallets</p>
            ) : (
              connectedWallet.map((wallet) => (
                <img
                  src={getWalletIcons()?.[wallet.name]}
                  width={26}
                  height={26}
                  alt="img"
                  className="bw-border bw-max-w-[26px] bw-max-h-[26px] bw-min-w-[26px] bw-min-h-[26px] bw-ml-[-10px] bw-border-border-primary bw-rounded-[50%] bw-bg-background-coin"
                />
              ))
            )}
            {connectedWallet.length > 3 ? (
              <div className="bw-w-[26px] bw-h-[26px]  bw-border bw-border-border-primary bw-ml-[-10px] bw-text-sm bw-font-medium bw-flex bw-items-center bw-justify-center bw-rounded-[50%] bw-bg-background-coin">
                +{connectedWallet.length - 3}
              </div>
            ) : (
              <></>
            )}
          </div>
          {isNoWallet ? (
            <></>
          ) : (
            <div
              className={`${
                open ? "bw-rotate-180" : ""
              } bw-absolute bw-right-2 bw-ml-auto`}
            >
              <Down fill={"var(--tw-text-primary)"} />
            </div>
          )}
        </button>
        <SelectWalletPortfolio wallet="connected" isNoWallet={isNoWallet} />
      </div>
      <Menu
        id="connect-wallet"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ margin: "3px" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: {
            padding: 0,
            background: "var(--tw-background-container)",
          },
        }}
        elevation={0}
      >
        <MenuList className="bw-bg-background-container bw-py-0  bw-border bw-w-[135px] bw-rounded-md bw-border-border-primary ">
          {connectedWallet.map((wallet, i, arr) => (
            <MenuItem
              key={i}
              className="bw-py-0 bw-px-1 bw-justify-center hover:bw-bg-transparent "
              onClick={handleClose}
            >
              <WalletDetailsPortfolio
                walletName={wallet.name}
                walletDetails={getWallet(wallet)}
                i={i == arr.length - 1}
              />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
