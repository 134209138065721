import { useState } from "react";
import images from "../../images";
import Search from "../Svg/search";
import BackButton from "../Svg/backButton";
export default function ShowMoreNetworks({
  data,
  handleBack,
  handleSetChainData,
  network,
}) {
  const [networkData, setNetworkData] = useState(data);
  const [value, setValue] = useState("");
  const { backbutton, search, tick } = images;
  return (
    <div>
      <div className="bw-flex bw-relative bw-justify-center bw-mb-2">
        <button
          onClick={() => {
            handleBack();
          }}
          className="bw-absolute bw-left-0 bw-top-[25%]"
        >
          <BackButton fill={"var(--tw-text-secondary)"} />
        </button>
        <div className="bw-text-base bw-font-normal bw-text-text-secondary">
          Network
        </div>
      </div>
      <div
        style={
          value.length
            ? {
                background: "linear-gradient(to right,#A45EFF   ,#2CFFE4)",
                padding: "1px",
                borderRadius: "5px",
              }
            : {}
        }
        className="bw-relative"
      >
        <input
          type={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          className={`bw-text-sm bw-px-2 bw-w-full bw-h-[34px] ${
            !value.length
              ? "bw-border bw-border-border-primary"
              : "bw-border-0 bw-border-transparent"
          } bw-rounded-[5px] bw-font-normal bw-bg-background-container placeholder:bw-text-text-placeholder bw-text-text-primary`}
          placeholder="Search Network"
        />
        <div className="bw-absolute bw-right-2 bw-bg-background-container bw-top-[6px]">
          <Search fill={"var(--tw-background-secondary)"} />
        </div>
      </div>
      <div className="bw-h-[500px] bw-pr-2 bw-mt-3 bw-overflow-y-auto">
        {networkData?.length &&
          networkData
            .filter((item) => {
              return (
                item.name.toLowerCase().includes(value.toLowerCase()) &&
                item.networkType !== "cosmos"
              );
            })
            .map((item, i) => {
              return (
                <div
                  onClick={() => {
                    let newObj = { ...network, chain: item.name, ...item };
                    handleBack(item);
                    handleSetChainData(newObj);
                  }}
                  className={`first-letter:bw-py-2 bw-cursor-pointer bw-border-b bw-flex bw-items-center bw-justify-between bw-gap-x-3 bw-border-border-primary bw-text-sm bw-font-normal bw-text-text-primary 
                
                `}
                  key={i}
                >
                  <div className="bw-flex bw-items-center bw-gap-x-3">
                    {" "}
                    <img
                      src={item.image}
                      className="bw-rounded-[50%]"
                      width={25}
                      height={25}
                      alt="img"
                    />{" "}
                    <p>{item.name}</p>
                    {network.chain === item.name ? (
                      <img src={tick} width={10} height={10} alt="img" />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="bw-pr-2">
                    <p className="bw-text-xs bw-font-normal">Tokens</p>
                    <p className="bw-text-xs bw-text-right bw-font-normal">
                      {item?.tokenCount || 0}
                    </p>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}

// ${
//   walletNetwork == "sol"
//     ? item.name?.toLowerCase() == "solana"
//       ? ""
//       : "pointer-events-none opacity-60"
//     : item.name?.toLowerCase() == "solana"
//     ? "pointer-events-none opacity-60"
//     : ""
// }
