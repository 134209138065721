import ComingSoon from "../../Tooltip/Comingsoon";
import RoundedButton from "../../Button/RoundedButton";
import Hamburger from "../../Svg/hamburger";
import BackButton from "../../Svg/backButton";
import useStore from "../../../zustand/store";
export default function PortfolioHeader() {
  const setRoute = useStore((state) => state.setRoute);
  return (
    <div className="bw-flex bw-mb-3  bw-items-center bw-justify-between">
      <button
        onClick={() => {
          setRoute("");
        }}
        className=" "
      >
        <BackButton fill={"var(--tw-text-primary)"} />
      </button>
      <p className="bw-text-lg bw-font-semibold bw-text-text-primary">
        Portfolio
      </p>
      <ComingSoon>
        <div className="bw-flex bw-items-center bw-gap-x-2">
          <RoundedButton classnames={" "}>
            <Hamburger fill={"var(--tw-text-primary)"} />
          </RoundedButton>
        </div>
      </ComingSoon>
    </div>
  );
}
