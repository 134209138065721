import images from "../../../images";

import SelectWalletPortfolio from "../SelectWalletPortfolio";

export default function PortfolioDisconnect() {
  const { barchartdisconnect } = images;

  return (
    <div className="bw-w-full bw-flex bw-flex-col bw-items-center">
      <img src={barchartdisconnect} alt="img" />
      <p className="bw-text-lg bw-font-medium bw-text-center bw-text-text-secondary">
        Connect multiple wallets and monitor your portfolio across 26 chains.
      </p>
      <SelectWalletPortfolio />
    </div>
  );
}
