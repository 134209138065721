import { useMemo } from "react";

export default function AssetsText({ chains, assets }) {
  let chainCount = useMemo(
    () => chains.filter((item) => item.value !== 0),
    [chains]
  );
  return assets.length && chains.length
    ? `${assets?.length} Assets on ${chainCount?.length} chains`
    : "";
}
