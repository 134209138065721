import styles from "./WidgetForm.module.css";
import useStore from "../../zustand/store";
import { isEmpty } from "lodash";

export default function QuotesProgress({ routesData, routes, quoteTimer }) {
  const configuration = useStore((state) => state.configuration);
  const gradient = useStore((state) => state.gradient);

  return !isEmpty(routesData) && !routes.isFetching && routes.isSuccess ? (
    <div className="bw-flex bw-items-center bw-gap-x-2 bw-justify-between">
      <p className="bw-text-sm bw-font-medium bw-text-text-secondary">
        New Quote in
      </p>
      <div className=" bw-w-[250px] bw-flex-1 bw-bg-border-primary bw-h-[3px]">
        <div
          style={{
            height: "100%",
            ...configuration.gradientStyle,
            width: `${100 - (quoteTimer / 60) * 100}%`,
          }}
          className={` ${
            gradient
              ? gradient
              : `${
                  !configuration.gradientStyle?.background
                    ? "bw-bg-gradient-to-r  bw-from-[#A45EFF] bw-to-[#2CFFE4]"
                    : ""
                } ${styles.overlay}`
          } bw-relative  `}
        ></div>
      </div>
      <div
        style={{ ...configuration.gradientStyle }}
        className={`${
          gradient
            ? `${gradient} bw-bg-clip-text bw-text-transparent`
            : styles.gradienttext
        } bw-relative bw-text-right bw-w-max bw-text-2xl bw-font-semibold bw-text-transparent  `}
      >
        {quoteTimer}s
      </div>
    </div>
  ) : (
    <></>
  );
}
