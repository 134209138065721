export default function B({ fill }) {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.96762 0.608257C9.75204 0.81844 10.4162 1.32448 10.8223 2.02798C11.1795 2.64657 11.3164 3.35994 11.2014 4.05707C11.8556 4.29389 12.405 4.76918 12.7621 5.38777L12.7691 5.3999C13.1753 6.10339 13.2814 6.93156 13.0713 7.71597C12.8611 8.50039 12.355 9.16451 11.6515 9.57067L6.18127 12.7289C5.64759 13.0371 4.95884 12.8525 4.65071 12.3188C4.34259 11.7851 4.52714 11.0964 5.06083 10.7883L10.5311 7.63C10.9192 7.40591 11.0525 6.90848 10.8284 6.52034L10.8214 6.50821C10.5973 6.12008 10.0999 5.98679 9.71177 6.21088L4.2415 9.36915C3.70781 9.67727 3.01906 9.49272 2.71094 8.95903C2.39581 8.41322 2.58736 7.7366 3.12105 7.42847L8.59132 4.27021C8.97946 4.04612 9.11274 3.54869 8.88165 3.14842C8.65756 2.76029 8.16013 2.627 7.77199 2.85109L2.30172 6.00936C1.76804 6.31748 1.08629 6.14506 0.771161 5.59924C0.463038 5.06556 0.647589 4.37681 1.18127 4.06868L6.65155 0.910419C7.35504 0.504257 8.18321 0.398074 8.96762 0.608257Z"
        fill={fill}
        fill-opacity="1"
      />
    </svg>
  );
}
