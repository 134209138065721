import React from "react";
import Star from "../Svg/star";
import controllers from "../../Actions/Controllers";
import { useQuery } from "react-query";
import { Skeleton } from "@mui/material";
import useStore from "../../zustand/store";
import useWalletDetails from "../../hooks/useWalletDetails";
import fixChar from "../../utils/fixChar";
function RewardsContent() {
  const { getWallet } = useWalletDetails({});
  const fromChain = useStore((state) => state.fromChain);
  const fromAddress = getWallet(fromChain)?.address || "";
  const points = useQuery(
    ["points", fromAddress],
    async () => {
      let req = await controllers.fetchPoints(fromAddress);
      return await req.json();
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <div className="bw-w-full  bw-z-10 bw-gap-y-1 bw-flex bw-flex-col bw-justify-center bw-items-center bw-h-full">
      <p className="bw-text-text-secondary bw-text-center bw-text-xs bw-font-normal bw-tracking-[0.3em]">
        REWARDS EARNED
      </p>
      <div className="bw-flex bw-justify-center bw-gap-x-2 bw-items-center">
        <div className="bw-w-[19px]  bw-h-[19px]">
          <Star />
        </div>
        {points.isLoading ? (
          <Skeleton
            className="bw-h-[32px] bw-rounded-xl bw-bg-background-loaderbar bw-w-[70px]"
            variant="rectangular"
          />
        ) : (
          <p className="bw-text-2xl bw-text-center bw-font-bold bw-text-text-primary">
            {fixChar(points?.data?.data?.points || 0, 8)} pts
          </p>
        )}
      </div>
      <div className="bw-flex bw-items-center bw-px-3 bw-justify-center bw-gap-x-[15%] bw-w-full">
        <div className="bw-text-text-secondary bw-text-sm bw-font-normal">
          <span>Trades</span> <span>{points?.data?.data?.tradeCount || 0}</span>
        </div>
        <div className="bw-text-text-secondary bw-text-center bw-text-sm bw-font-normal">
          <span>Volume</span>{" "}
          <span>${fixChar(points?.data?.data?.tradeVolume || 0, 8)}</span>
        </div>
        <div className="bw-text-text-secondary bw-text-center bw-text-sm bw-font-normal">
          <span>Fees</span>{" "}
          <span>${fixChar(points?.data?.data?.tradeFee || 0, 8)}</span>
        </div>
      </div>
    </div>
  );
}

export default RewardsContent;
