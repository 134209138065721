import LeftGlow from "../Svg/leftGlow";
import RightGlow from "../Svg/rightGlow";
import RewardsContent from "./rewardsContent";
import useStore from "../../zustand/store";
export default function Rewards() {
  let configuration = useStore((state) => state.configuration);
  return (
    <div className="bw-w-[calc(100%+20px)] bw-flex  bw-py-2 bw-ml-[-10px] bw-relative bw-h-max bw-mt-2 bw-justify-center bw-items-center ">
      <div
        className={`bw-w-full bw-overflow-y-hidden  bw-absolute bw-left-0 bw-h-full bw-bg-background-rewards`}
      >
        <div
          style={{ mixBlendMode: "multiply" }}
          className="bw-mr-auto  bw-absolute bw-left-0 bw-top-0"
        >
          <LeftGlow />
        </div>
        <div
          style={{ mixBlendMode: "multiply" }}
          className="bw-ml-auto bw-absolute bw-right-0 bw-top-0"
        >
          <RightGlow />
        </div>
      </div>
      <RewardsContent />
    </div>
  );
}
