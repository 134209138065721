import React, { useState } from "react";
import images from "../../images";
import DisconnectIcon from "../Svg/disconnecticon";
import useStore from "../../zustand/store";
import { CircularProgress } from "@mui/material";
export default function WalletDetails({
  walletDetails,
  handleToDisconnect,
  show,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { address, chain, data, disconnect, ...rest } = walletDetails;
  const open = Boolean(anchorEl);
  const { disconnecticon } = images;
  const customWallet = useStore((state) => state.customWallet);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        id="basic-button"
        style={{ fontSize: "12px" }}
        className="bw-bg-background-container bw-flex bw-items-center bw-justify-center bw-gap-x-2 bw-mb-[4px] bw-w-[100px] bw-h-[25px] bw-rounded-xl bw-border bw-border-border-primary"
      >
        {address ? (
          <>
            <p className="bw-text-text-primary bw-text-xs bw-font-normal">
              {(address?.substring(0, 4) || "") +
                "..." +
                (address?.substring(address.length - 4, address.length) || "")}
            </p>
            <div
              onClick={() => {
                if (show == "to") {
                  // if (customWallet?.length > 30) {
                  disconnect();
                  // }
                  handleToDisconnect(true);
                } else {
                  disconnect();
                }
              }}
            >
              {/* <img src={disconnecticon} /> */}
              <DisconnectIcon fill={"var(--tw-text-primary)"} />
            </div>
          </>
        ) : (
          <div className="bw-w-[10px] bw-flex bw-text-text-secondary bw-justify-center bw-items-center bw-h-[10px]">
            <CircularProgress size={10} thickness={5} color="inherit" />
          </div>
        )}
      </button>
    </>
  );
}
