export default function setColors(configuration) {
  document.documentElement.style.setProperty(
    "--tw-text-primary",
    configuration?.customTheme?.text?.primary || ""
  );
  document.documentElement.style.setProperty(
    "--tw-text-secondary",
    configuration?.customTheme?.text?.secondary || ""
  );
  document.documentElement.style.setProperty(
    "--tw-text-selected",
    configuration?.customTheme?.text?.selected || ""
  );
  document.documentElement.style.setProperty(
    "--tw-text-placeholder",
    configuration?.customTheme?.text?.placeholder || ""
  );
  document.documentElement.style.setProperty(
    "--tw-text-button",
    configuration?.customTheme?.text?.button || ""
  );
  document.documentElement.style.setProperty(
    "--tw-text-greentext",
    configuration?.customTheme?.text?.success || ""
  );
  document.documentElement.style.setProperty(
    "--tw-text-redtext",
    configuration?.customTheme?.text?.error || ""
  );

  document.documentElement.style.setProperty(
    "--tw-background-container",
    configuration?.customTheme?.background?.container || ""
  );
  document.documentElement.style.setProperty(
    "--tw-background-secondary",
    configuration?.customTheme?.background?.secondary || ""
  );
  document.documentElement.style.setProperty(
    "--tw-background-networkcard",
    configuration?.customTheme?.background?.networkCard || ""
  );
  document.documentElement.style.setProperty(
    "--tw-background-card",
    configuration?.customTheme?.background?.card || ""
  );
  document.documentElement.style.setProperty(
    "--tw-background-disable",
    configuration?.customTheme?.background?.disable || ""
  );
  document.documentElement.style.setProperty(
    "--tw-background-loaderbar",
    configuration?.customTheme?.background?.loaderbar || ""
  );
  document.documentElement.style.setProperty(
    "--tw-background-coin",
    configuration?.customTheme?.background?.coin || ""
  );
  document.documentElement.style.setProperty(
    "--tw-background-rewards",
    configuration?.customTheme?.background?.rewards || ""
  );

  document.documentElement.style.setProperty(
    "--tw-border-primary",
    configuration?.customTheme?.border?.primary || ""
  );
  document.documentElement.style.setProperty(
    "--tw-border-secondary",
    configuration?.customTheme?.border?.secondary || ""
  );
  document.documentElement.style.setProperty(
    "--tw-border-inputHighlight",
    configuration?.customTheme?.border?.inputHighlight || ""
  );
}
