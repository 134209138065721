import Tooltip from "@mui/material/Tooltip";

export default function TxnUrl({ children }) {
  return (
    <Tooltip
      onClose={() => {}}
      disableInteractive
      onClick={() => {}}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
      className="bw-text-text-button"
      title="Transaction Link"
    >
      {children}
    </Tooltip>
  );
}
