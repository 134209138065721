import Tooltip from "@mui/material/Tooltip";

export default function Routes({ name, children }) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "var(--tw-background-container)",
            "& .MuiTooltip-arrow": {
              color: "var(--tw-bg-container)",
            },
            border: "1px solid var(--tw-border-primary)",
            color: "var(--tw-text-primary)",
            boxShadow: "0px 0px 6.1px 0px #0000001A",
          },
        },
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      className="bw-text-text-button"
      title={name}
      placement="top"
    >
      {children}
    </Tooltip>
  );
}
