import React from "react";

function InProg({ fill, stroke }) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="14"
        height="14.2277"
        rx="7"
        fill={fill}
        stroke={stroke}
        opacity={"0.6"}
      />
      <ellipse
        cx="4.06393"
        cy="7.98006"
        rx="1.1655"
        ry="1.18319"
        fill="#D9D9D9"
      />
      <ellipse
        cx="11.603"
        cy="7.98006"
        rx="1.1655"
        ry="1.18319"
        fill="#D9D9D9"
      />
      <ellipse
        cx="7.83347"
        cy="6.29061"
        rx="1.1655"
        ry="1.18319"
        fill="#D9D9D9"
      />
    </svg>
  );
}

export default InProg;
