import React, { useEffect } from "react";
import {
  useAccount,
  useBalance,
  useStargateClient,
  useSendIbcTokens,
} from "graz";
import useCosmosTxn from "../hooks/useCosmosTxn";
import { decodeTxRaw } from "@cosmjs/proto-signing";
import {
  useStargateSigningClient,
  useOfflineSigners,
  useConnect,
  useSendTokens,
} from "graz";
import { encodeBech32Pubkey } from "@cosmjs/launchpad";
import accSum from "add";
import { MsgTransfer } from "cosmjs-types/ibc/applications/transfer/v1/tx";
console.log(MsgTransfer, "msg trnasfer");
function SendTx() {
  const { data: accounts } = useAccount();
  const { data } = useOfflineSigners();
  const { connect } = useConnect();
  const { sendTokens, sendTokensAsync } = useSendTokens();
  const { sendIbcTokensAsync } = useSendIbcTokens();
  const { data: stargateClient } = useStargateClient();
  console.log(stargateClient, accounts, "Stargate");
  const {
    data: signingClient,
    isFetching,
    refetch,
  } = useStargateSigningClient({
    chainId: ["cosmoshub-4"],
  });
  useEffect(() => {
    let res = connect({ chainId: "cosmoshub-4" });
    console.log(res, "Res12");
  }, []);
  const { data: balance, isLoading } = useBalance({
    denom: "uatom",
    bech32Address: accounts?.bech32Address,
  });
  const { sendTransaction } = useCosmosTxn();
  console.log(accounts, balance, signingClient, data, "accounts");
  async function sendTokens1() {
    const currentHeight = await signingClient.getHeight();
    console.log(currentHeight, "height");
    const msg = {
      sourcePort: "transfer",
      sourceChannel: "channel-293", // Replace X with the correct channel number
      token: {
        denom: "uatom", // Or the appropriate denom
        amount: "10000", // Amount in smallest unit (e.g., 1 ATOM = 1000000 uatom)
      },
      sender: accounts?.bech32Address, // Your Cosmos address
      receiver: "0x1b1E919E51a1592Dce70a4FD74107941109B8235", // The EVM address on the destination chain
      timeoutHeight: currentHeight + 1000,
      timeoutTimestamp: Math.floor(new Date().getTime() / 1000) + 600, // 10 minutes from now
    };
    const msgTransfer = {
      typeUrl: "/ibc.applications.transfer.v1.MsgTransfer",
      value: MsgTransfer.fromPartial(msg),
    };
    const fee = {
      amount: [{ denom: "uatom", amount: "5000" }],
      gas: "200000",
    };
    // const result = await sendIbcTokensAsync({
    //   signingClient,
    //   senderAddress: accounts?.bech32Address,
    //   recipientAddress: "0x1b1E919E51a1592Dce70a4FD74107941109B8235",
    //   transferAmount: {
    //     denom: "uatom",
    //     amount: "10000",
    //   },
    //   sourcePort: "transfer",
    //   sourceChannel: "channel-293",
    //   fee,
    //   timeoutTimestamp: Math.floor(new Date().getTime() / 1000) + 600,
    // });
    const result = await signingClient.signAndBroadcast(
      accounts?.bech32Address,
      [msgTransfer],
      fee
    );
    console.log(result, "result");
    // try {
    //   let res = await sendTokensAsync({
    //     signingClient,
    //     recipientAddress: "",
    //     amount: [{ denom: "uatom", amount: "10000" }],
    //     fee: {
    //       amount: [{ denom: "uatom", amount: "500" }],
    //       gas: "200000",
    //     },
    //   });
    //   console.log(res, "res");
    // } catch (e) {
    //   console.log(e, "sendError");
    // }
  }
  const cosmosAddress = "cosmos1..."; // Your Cosmos address
  const gravityBridgeContractAddress = "0x..."; // Gravity Bridge contract address on EVM
  const amount = window.BigInt("10000"); // Amount in smallest units (e.g., uatom)

  async function sendTokensToGravityBridge() {
    // Create the message to send tokens
    const msg = {
      typeUrl: "/cosmos.bank.v1beta1.MsgSend",
      value: {
        fromAddress: accounts?.bech32Address,
        toAddress: "0x1b1E919E51a1592Dce70a4FD74107941109B8235",
        amount: [{ denom: "uatom", amount: amount }],
      },
    };

    // Define fee and memo
    const fee = {
      amount: [{ denom: "uatom", amount: "5000" }],
      gas: "200000",
    };
    const memo = "Bridging tokens via Gravity Bridge";

    // Sign and broadcast the transaction
    const tx = await signingClient.signAndBroadcast(
      accounts?.bech32Address,
      [msg],
      {
        fee: fee,
        memo: memo,
      }
    );

    if (tx.code === 0) {
      console.log("Transaction successful:", tx);
    } else {
      console.error("Transaction failed:", tx.log || tx.rawLog);
    }
  }
  console.log(accounts?.bech32Address, "accounts");
  return (
    <button
      onClick={() => {
        // sendTokens1();
        sendTokensToGravityBridge().catch(console.error);
      }}
      className="bw-text-text-primary"
    >
      Send Txn
    </button>
  );
}

export default SendTx;
