import React from "react";
import styles from "./TimeLoader.module.css";
function TimeLoader() {
  return (
    <div className={styles.loader}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default TimeLoader;
