import React from "react";
import useStore from "../../zustand/store";
function RightGlow() {
  let configuration = useStore((state) => state.configuration);
  let theme = useStore((state) => state.theme);
  return (
    <svg
      width="166"
      height="102"
      viewBox="0 0 166 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 102L201 26.8661V0L0.69072 41.4581L0 102Z"
        fill="url(#paint0_linear_3378_15128)"
        fill-opacity="1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3378_15128"
          x1="14.693"
          y1="12.0645"
          x2="381.278"
          y2="12.0645"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stop-color={
              theme === "light" &&
              !configuration?.customTheme?.background?.rewards?.length
                ? "rgba(255, 255, 255, 0)"
                : "rgba(31, 31, 31, 0)"
            }
          />
          <stop
            offset="1"
            stop-color={
              theme === "light" &&
              !configuration?.customTheme?.background?.rewards?.length
                ? "rgba(167, 167, 167, 1)"
                : "rgba(255, 255, 255, 1)"
            }
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default RightGlow;
