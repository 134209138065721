import images from "../images";
export default function getWalletIcons() {
  const {
    injectedicon,
    metamaskicon,
    walletconnecticon,
    coinbaseicon,
    phantom,
    solflare,
    slope,
    magiceden,
    backpacksol,
    tronLink,
    keplr,
  } = images;
  const walletIcons = {
    browserwallet: injectedicon,
    metamask: metamaskicon,
    coinbasewallet: coinbaseicon,
    walletconnect: walletconnecticon,
    solflare,
    phantom,
    slope,
    backpacksol,
    keplr,
    "magic eden (solana)": magiceden,
    TronLink: tronLink,
  };
  return walletIcons;
}
