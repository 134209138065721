import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import PortfolioTableHeader from "./PortfolioTableHeader";
import Paper from "@mui/material/Paper";
import { useEffect, useMemo, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import PortfolioTableRow from "./PortfolioTableRow";
import { useQuery } from "react-query";
import controllers from "../../../Actions/Controllers";
import { keyBy } from "lodash";
import { COLORSCOIN } from "../../../utils/getColors";
import truncate from "../../../utils/truncate";
export default function PortfolioTable({ data }) {
  const [tableData, setTableData] = useState([]);
  const [hide, setHide] = useState(false);
  const [chainData, setChainData] = useState({});
  const memoData = useMemo(() => {
    let addColor = [];
    data?.forEach((item, i) => {
      addColor.push({
        ...item,
        color: i <= 10 ? COLORSCOIN[i] : COLORSCOIN[10],
      });
    });
    return addColor;
  }, [data]);
  function handleData(data1) {
    // if (data.length) {
    setTableData([...data1]);
    // }
  }
  useEffect(() => {
    handleData(memoData);
  }, [memoData]);

  const fetchChains = useQuery(
    ["chainsportfoliotable"],
    async () => {
      let res = await controllers.fetchChain();
      return await res.json();
    },
    {
      onSuccess: (data) => {
        let chainObj = keyBy(data?.data, "chainId");
        setChainData({ ...chainObj });
      },
      refetchOnWindowFocus: false,
      enabled: data?.length ? true : false,
    }
  );
  return (
    <div className="bw-mt-0 bw-relative ">
      <div>
        {memoData?.length ? (
          <div className="bw-absolute bw-flex bw-items-center bw-gap-x-0   bw-top-[-7%]  bw-right-0">
            <Checkbox
              size="small"
              onChange={(e) => {
                setHide(!e.target.checked);
              }}
              defaultChecked={true}
              disableRipple
              sx={{
                width: "10px",
                height: "10px",
                marginRight: "3px",

                color: "var(--tw-text-secondary)",
                "&.Mui-checked": {
                  color: "var(--tw-text-primary)",
                  opacity: "1",
                },
                "& .MuiSvgIcon-root": { fontSize: 18 },
              }}
            />
            <p className="bw-text-text-secondary bw-text-[10px] bw-font-medium ">
              {`Hide <$0`}
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
      {tableData.length ? (
        <TableContainer
          className=" bw-rounded-none bw-bg-background-container bw-shadow-none bw-border-0"
          component={Paper}
        >
          <Table
            className="bw-bg-background-container  bw-w-full"
            aria-label="simple table"
          >
            <PortfolioTableHeader
              tableData={tableData}
              handleData={handleData}
              data={memoData}
            />
            <TableBody className="bw-block bw-w-full bw-mt-1 bw-h-[320px]  bw-overflow-y-auto">
              {tableData
                .filter((item) =>
                  !hide ? (item.value < 0.1 ? false : true) : true
                )
                .map((row, i) => (
                  <PortfolioTableRow key={i} row={row} chainData={chainData} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
    </div>
  );
}
