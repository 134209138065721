import truncate from "../../../utils/truncate";

export default function CustomTooltip(props) {
  const { payload } = props;
  return (
    <div className="bw-bg-background-container bw-text-center  bw-border bw-border-border-primary bw-relative bw-z-30 bw-rounded-md bw-p-3 bw-text-text-primary bw-text-xs bw-font-bold bw-min-w-[100px] bw-min-h-[45px] bw-shadow">
      <span className="bw-mr-1">
        {typeof payload[0]?.payload?.balance === "undefined"
          ? payload[0]?.asset?.name
          : payload[0]?.payload?.asset?.symbol}
      </span>
      <span>{truncate(payload[0]?.payload.percentShare * 100, 4) || 0}%</span>
      <p className="bw-text-center">
        ${truncate(payload[0]?.payload.value, 4) || 0}
      </p>
    </div>
  );
}
