import SortUp from "../../Svg/sortUp";
import SortDown from "../../Svg/sortDown";
import TableHead from "@mui/material/TableHead";
import { useState, useEffect } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
export default function PortfolioTableHeader({ data, tableData, handleData }) {
  const [priceSort, setPriceSort] = useState("none");
  const [balanceSort, setBalanceSort] = useState("none");
  const [valueSort, setValueSort] = useState("none");

  function handlePriceSort(stateHandle) {
    stateHandle((prev) => {
      if (prev == "none") {
        return "asc";
      } else if (prev === "asc") {
        return "desc";
      } else {
        return "none";
      }
    });
  }
  useEffect(() => {
    if (tableData.length) {
      let tempArray = [...tableData];
      if (priceSort === "none") {
        handleData([...data]);
      } else if (priceSort === "asc") {
        tempArray.sort(
          (a, b) => (a?.asset?.lastPrice || 0) - (b.asset?.lastPrice || 0)
        );
        handleData([...tempArray]);
      } else if (priceSort === "desc") {
        tempArray.sort(
          (a, b) => (b.asset?.lastPrice || 0) - (a.asset?.lastPrice || 0)
        );
        handleData([...tempArray]);
      }
    }
  }, [priceSort]);
  useEffect(() => {
    if (tableData.length) {
      let tempArray = [...tableData];
      if (valueSort === "none") {
        handleData([...data]);
      } else if (valueSort === "asc") {
        tempArray.sort((a, b) => (a.value || 0) - (b.value || 0));
        handleData([...tempArray]);
      } else if (valueSort === "desc") {
        tempArray.sort((a, b) => (b.value || 0) - (a.value || 0));
        handleData([...tempArray]);
      }
    }
  }, [valueSort]);
  useEffect(() => {
    if (tableData.length) {
      let tempArray = [...tableData];
      if (balanceSort === "none") {
        handleData([...data]);
      } else if (balanceSort === "asc") {
        tempArray.sort(
          (a, b) =>
            (a.balance / Math.pow(10, a.asset?.decimals) || 0) -
            (b.balance / Math.pow(10, b.asset?.decimals) || 0)
        );
        handleData([...tempArray]);
      } else if (balanceSort === "desc") {
        tempArray.sort(
          (a, b) =>
            (b.balance / Math.pow(10, b.asset?.decimals) || 0) -
            (a.balance / Math.pow(10, a.asset?.decimals) || 0)
        );
        handleData([...tempArray]);
      }
    }
  }, [balanceSort]);
  return (
    <TableHead className="bw-w-full bw-block">
      <TableRow className="bw-w-full bw-block">
        <TableCell className=" bw-w-[50%]  bw-select-none bw-pl-2 bw-sticky bw-z-10 bw-top-0 bw-bg-background-secondary bw-text-sm bw-font-normal bw-text-text-secondary bw-border-0 bw-py-0 bw-min-h-max">
          Token
        </TableCell>
        {/* <TableCell
          className="bw-sticky bw-select-none   bw-cursor-pointer bw-top-0 bw-bg-background-secondary bw-text-sm bw-font-normal bw-text-text-secondary bw-border-0 bw-py-0 bw-min-h-max"
          align="right"
        >
          <div
            onClick={() => {
              setValueSort("none");
              setBalanceSort("none");
              handlePriceSort(setPriceSort);
            }}
            className="bw-flex bw-items-center bw-gap-x-2 "
          >
            <p> Price</p>
            <div className="bw-flex bw-items-center bw-flex-col bw-justify-center">
              <SortUp
                isSelected={priceSort == "asc" ? true : false}
                fill={"var(--tw-text-primary)"}
              ></SortUp>
              <SortDown
                fill={"var(--tw-text-primary)"}
                isSelected={priceSort == "desc" ? true : false}
              />
            </div>
          </div>
        </TableCell> */}
        <TableCell
          className="bw-sticky bw-select-none bw-w-[35%] bw-cursor-pointer bw-top-0 bw-bg-background-secondary  bw-text-sm bw-font-normal bw-text-text-secondary bw-border-0 bw-py-0 bw-min-h-max"
          align=""
        >
          <div
            onClick={() => {
              setPriceSort("none");
              setValueSort("none");
              handlePriceSort(setBalanceSort);
            }}
            className="bw-flex  bw-items-center bw-justify-center bw-gap-x-2"
          >
            Balance
            <div className="bw-flex bw-select-none bw-gap-y-[2px]  bw-items-center bw-flex-col bw-justify-center">
              <SortUp
                isSelected={balanceSort == "asc" ? true : false}
                fill={"var(--tw-text-primary)"}
              ></SortUp>
              <SortDown
                fill={"var(--tw-text-primary)"}
                isSelected={balanceSort == "desc" ? true : false}
              />
            </div>
          </div>
        </TableCell>
        <TableCell
          className="bw-sticky bw-cursor-pointer bw-min-w-[21%] bw-pr-2 bw-top-0 bw-bg-background-secondary bw-text-sm bw-font-normal bw-text-text-secondary bw-border-0 bw-py-0 bw-min-h-max"
          align="right"
        >
          <div
            onClick={() => {
              setPriceSort("none");
              setBalanceSort("none");
              handlePriceSort(setValueSort);
            }}
            className="bw-flex bw-justify-end bw-items-center bw-gap-x-2"
          >
            Value
            <div className="bw-flex bw-gap-y-[2px] bw-items-center bw-flex-col bw-justify-center">
              <SortUp
                isSelected={valueSort == "asc" ? true : false}
                fill={"var(--tw-text-primary)"}
              ></SortUp>
              <SortDown
                fill={"var(--tw-text-primary)"}
                isSelected={valueSort == "desc" ? true : false}
              />
            </div>
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
