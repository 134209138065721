import React, { useState, useEffect } from "react";
import DateBack from "../Svg/dateBack";
import useStore from "../../zustand/store";
import styles from "./DatePicker.module.css";
import { inRange } from "lodash";
const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const DateRangePicker = ({ handleSubmit, handleClose }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const configuration = useStore((state) => state.configuration);
  const [secondMonth, setSecondMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
  );
  useEffect(() => {
    setSecondMonth(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  }, [currentDate]);
  const getDaysInMonth = (date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const getFirstDayOfMonth = (date) =>
    new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (date > startDate) {
      setEndDate(date);
    } else {
      setEndDate(startDate);
      setStartDate(date);
    }
  };
  const renderCalendar = (month) => {
    const daysInMonth = getDaysInMonth(month);
    const firstDayOfMonth = getFirstDayOfMonth(month);
    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(
        <div
          key={`empty-${i}-${month}`}
          style={{ width: "30px", height: "30px" }}
        ></div>
      );
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(month.getFullYear(), month.getMonth(), day);
      const isSelected =
        (startDate && date.getTime() === startDate.getTime()) ||
        (endDate && date.getTime() === endDate.getTime());
      let styleGrad =
        configuration?.gradientStyle &&
        isSelected &&
        configuration?.gradientStyle;
      const isInRange =
        startDate && endDate && date >= startDate && date <= endDate;
      days.push(
        <div
          key={`${day}-${month}`}
          onClick={() => handleDateClick(date)}
          style={{
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            ...styleGrad,

            backgroundColor: isSelected
              ? "#3B82F6"
              : isInRange
              ? "#DBEAFE"
              : "transparent",
            color: isSelected
              ? "var(--tw-text-primary)"
              : "var(--tw-text-secondary)",
          }}
          className={`${
            !configuration?.gradientStyle && isSelected && styles.gradientbutton
          } ${
            isInRange && "bw-bg-border-primary"
          } bw-p-[1px] bw-mb-1 bw-rounded-[4px]`}
        >
          <div
            className={`bw-w-full  bw-rounded-[4px] bw-flex bw-justify-center bw-items-center bw-h-full bw-bg-background-container`}
          >
            {day}
          </div>
        </div>
      );
    }
    return days;
  };
  const changeMonth = (increment) => {
    setCurrentDate(
      (prevDate) =>
        new Date(prevDate.getFullYear(), prevDate.getMonth() + increment, 1)
    );
  };
  const formatDate = (date) => {
    return date
      ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")}`
      : "";
  };
  const nightsCount =
    startDate && endDate
      ? Math.round((endDate - startDate) / (1000 * 60 * 60 * 24))
      : 0;
  return (
    <div
      style={{
        padding: "16px",
        backgroundColor: "var(--tw-background-container)",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        maxWidth: "335px",
        margin: "0 auto",
      }}
      className="bw-border bw-border-border-primary bw-rounded-xl"
    >
      <div
        onClick={() => {
          handleClose();
        }}
        className="bw-mb-2 bw-cursor-pointer bw-flex bw-justify-between bw-items-center bw-w-full"
      >
        <p className="bw-text-text-placeholder bw-text-xs bw-font-medium">
          Select Date
        </p>
        <div className="bw-w-[20px]  bw-flex bw-justify-center bw-pb-[2px] bw-items-center bw-text-border-primary bw-h-[20px] bw-border bw-border-border-primary bw-rounded-[50%]">
          x
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={() => changeMonth(-1)}
            style={{
              padding: "4px",
              border: "none",
              background: "none",
              cursor: "pointer",
              transform: "rotate(180deg)",
            }}
          >
            <DateBack fill={"var(--tw-text-primary)"} />
          </button>
          <h2 className="bw-text-lg bw-font-bold bw-text-text-primary">
            {MONTHS[currentDate.getMonth()]} {currentDate.getFullYear()}
          </h2>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 className="bw-text-lg bw-font-bold bw-text-text-primary">
            {MONTHS[secondMonth.getMonth()]} {secondMonth.getFullYear()}
          </h2>
          <button
            onClick={() => changeMonth(1)}
            style={{
              padding: "4px",
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
          >
            <DateBack fill={"var(--tw-text-primary)"} />
          </button>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {[currentDate].map((month, index) => (
          <div key={index} style={{ width: "100%", padding: "0 8px" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",

                marginBottom: "8px",
              }}
            >
              {DAYS.map((day) => (
                <div
                  key={day}
                  className="bw-text-[10px] bw-text-text-secondary bw-font-medium"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  {day}
                </div>
              ))}
            </div>
            <div
              className="bw-text-sm bw-text-text-secondary bw-font-medium"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
              }}
            >
              {renderCalendar(month)}
            </div>
          </div>
        ))}
      </div>
      {/* <div style={{ marginTop: "16px" }}>
        <p style={{ fontWeight: "bold" }}>
          Selected: {formatDate(startDate)} - {formatDate(endDate)}
        </p>
      </div> */}
      <div
        style={{
          marginTop: "8px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          onClick={() => {
            startDate &&
              endDate &&
              handleSubmit(startDate, endDate, nightsCount);
          }}
          style={{
            // backgroundColor: "#3B82F6",
            color: "white",
            border: "none",
            borderRadius: "4px",
            height: "38px",
            cursor: "pointer",
            width: "100%",
            padding: "1px",
            ...configuration?.gradientStyle,
            boxShadow:
              (configuration &&
                configuration.customTheme &&
                configuration.customTheme?.shadow?.boxShadow) ||
              "1px 1px 7px rgba(0, 0, 0, 0.15)",
          }}
          className={`${
            !configuration?.gradientStyle?.background && styles.gradientbutton
          } p-[1px]`}
        >
          <div
            className={`bw-w-full bw-rounded-[4px] bw-flex bw-justify-center bw-items-center bw-h-full bw-bg-background-container bw-text-text-secondary bw-text-lg bw-font-normal`}
          >
            Submit
          </div>
        </button>
      </div>
      <div className="bw-flex bw-justify-center bw-mt-2">
        <button
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
          }}
          className="bw-text-xs bw-text-text-secondary bw-underline"
        >
          Clear
        </button>
      </div>
    </div>
  );
};
export default DateRangePicker;
