export default function Step({ fill }) {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8766 1.42789L1.33836 4.91515C0.818095 5.31493 0.817308 6.09897 1.33677 6.4998L5.982 10.0841C6.34405 10.3635 6.84948 10.3616 7.2095 10.0797L11.5036 6.71636C12.0033 6.32504 12.0167 5.57329 11.5314 5.16436L7.13026 1.45609C6.7709 1.1533 6.24921 1.14157 5.8766 1.42789Z"
        fill={fill}
        fill-opacity="1"
      />
      <path
        d="M0.660156 8.91797L5.23108 12.2646C5.93608 12.7807 6.89456 12.7795 7.59823 12.2615L12.0721 8.96811"
        stroke={fill}
        stroke-opacity="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
