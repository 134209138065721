import useStore from "../../zustand/store";
import styles from "./CosmosWallet.module.css";
import images from "../../images";
import {
  useAccount,
  useConnect,
  useDisconnect,
  WalletType,
  useActiveWalletType,
} from "graz";
import InstalledIcon from "../InstalledIcon";
export default function CosmosWallet({
  selectedWalletTab,
  handleCosmosConnection,
  setErrorMsg,
}) {
  const configuration = useStore((state) => state.configuration);
  const { connect } = useConnect({
    onError: (err) => setErrorMsg(err.message),
  });
  const { disconnect } = useDisconnect();
  const { data: accounts, isConnected } = useAccount();
  const { keplr } = images;
  const walletType = useActiveWalletType();
  console.log(walletType, "wallettype");
  const walletIcons = {
    keplr,
  };

  return (
    <div
      style={
        true
          ? {
              ...configuration?.gradientStyle,
            }
          : {}
      }
      className={`bw-w-[47%] bw-mb-4 ${
        selectedWalletTab === "Cosmos" ? "" : "  "
      } bw-rounded-md bw-p-[1px] ${
        !configuration.gradientStyle && isConnected
          ? styles.gradientbutton
          : "bw-bg-border-primary"
      } `}
      onClick={() => {
        if (isConnected) disconnect();
        else {
          connect({ chainId: "cosmoshub-4", walletType: WalletType.KEPLR });
          handleCosmosConnection(true, "keplr");
        }
      }}
    >
      <div
        className={` bw-relative bw-flex bw-w-full bw-bg-background-container  hover:bw-shadow-sm bw-font-medium  hover:bw-font-bold hover:bw-text-text-primary  bw-items-center bw-gap-x-3  bw-rounded-md  bw-h-[56px] bw-px-3 bw-cursor-pointer  ${
          ""
          // isFullyConnected ? "bw-pointer-events-none bw-opacity-50" : ""
        }${
          accounts?.bech32Address?.length && isConnected
            ? " bw-text-text-primary"
            : "bw-text-text-secondary"
        }`}
      >
        <img
          src={walletIcons["keplr"]}
          width={40}
          height={40}
          className="bw-max-h-[40px]"
          alt="img"
        />
        <p className="bw-text-xs bw-w-max ">Keplr</p>
        {accounts?.bech32Address?.length && isConnected ? (
          <div className="bw-text-[10px] bw-flex bw-items-center bw-gap-x-1 bw-mt-[-2px] bw-font-medium bw-absolute bw-top-1 bw-right-2">
            <div className="bw-w-[10px] bw-rounded-[50%] bw-bg-text-greentext bw-h-[10px]"></div>{" "}
            Connected
          </div>
        ) : window?.keplr ? (
          <div className="bw-absolute bw-top-1 bw-right-0">
            <InstalledIcon
              startColor={configuration?.gradientStyle?.spinnerColor}
              stopColor={configuration?.gradientStyle?.stopColor}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
