import React from "react";

function DateBack({ fill }) {
  return (
    <svg
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.996094 11.9219L5.90052 6.64019L0.996095 1.3585"
        stroke={fill}
        stroke-width="1.50905"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default DateBack;
