import Header from "../../Components/TxnHistoryComp/Header";
import Rewards from "../../Components/TxnHistoryComp/Rewards";
import TransactionData from "../../Components/TxnHistoryComp/TransactionData";
export default function TransactionHistory() {
  return (
    <div>
      <Header />
      <Rewards />
      <TransactionData />
    </div>
  );
}
