export const COLORSCHAIN = [
  "#ED462F",
  "#D8467A",
  "#6C544F",
  "#45975E",
  "#E4B21C",
];
export const COLORSCOIN = [
  "#F2ED31",
  "#FFB377",
  "#B4FF56",
  "#81DCFF",
  "#9C96FF",
  "#EDAAFF",
  "#FF8676",
  "#48FF80",
  "#FFDD78",
  "#F9CBCA",
  "#BABABA",
];
