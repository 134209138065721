import ConnectWallets from "./ConnectWallets";
import PortfolioData from "./PortfolioData";
import SelectChainDropdown from "./SelectChainDropDown";
import React, { useState } from "react";
export default React.memo(function PortfolioConnected() {
  const [selectChain, setSelectedChain] = useState([
    {
      name: "All Chains",
    },
  ]);
  function handleChain(e) {
    if (e) {
      let value = e?.name == "All Chains" ? [e] : e?.target?.value;
      setSelectedChain([...value]);
    }
  }
  return (
    <div>
      <div className="bw-flex bw-justify-between bw-items-center">
        <ConnectWallets />
        <SelectChainDropdown
          selectChain={selectChain}
          handleChain={handleChain}
        />
      </div>
      <div>
        <PortfolioData selectChain={selectChain} />
      </div>
    </div>
  );
});
