import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useState } from "react";
export default function useTronTxn() {
  const { signTransaction, wallets } = useWallet();
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  function resetTronData() {
    setData({});
    setError("");
  }
  async function handleTronTxn(data) {
    setError("");
    try {
      if (window.tronWeb && window.tronWeb.ready) {
        const signedTransaction = await signTransaction(data?.txnData?.txnTron);
        const res = await window.tronWeb.trx.sendRawTransaction(
          signedTransaction
        );
        setData(res);
      } else {
        setError("Please install Tron wallet");
      }
    } catch (err) {
      setError(err);
      console.log(err, "txnerror");
    }
  }
  return { tronData: data, tronError: error, handleTronTxn, resetTronData };
}
