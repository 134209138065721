export default function Exchange({ startColor, stopColor }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 513 528"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M300.601 12.7625C316.951 -3.58751 343.46 -3.58751 359.81 12.7625L500.61 153.563C512.584 165.536 516.166 183.544 509.686 199.189C503.205 214.833 487.939 225.034 471.006 225.034H42.7419C19.6195 225.034 0.875 206.289 0.875 183.167C0.875 160.044 19.6195 141.3 42.7419 141.3H369.93L300.601 71.9713C284.251 55.6213 284.251 29.1126 300.601 12.7625Z"
        fill="#A2A2A2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M300.601 12.7625C316.951 -3.58751 343.46 -3.58751 359.81 12.7625L500.61 153.563C512.584 165.536 516.166 183.544 509.686 199.189C503.205 214.833 487.939 225.034 471.006 225.034H42.7419C19.6195 225.034 0.875 206.289 0.875 183.167C0.875 160.044 19.6195 141.3 42.7419 141.3H369.93L300.601 71.9713C284.251 55.6213 284.251 29.1126 300.601 12.7625Z"
        fill="url(#paint0_linear_1483_26616)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M300.601 12.7625C316.951 -3.58751 343.46 -3.58751 359.81 12.7625L500.61 153.563C512.584 165.536 516.166 183.544 509.686 199.189C503.205 214.833 487.939 225.034 471.006 225.034H42.7419C19.6195 225.034 0.875 206.289 0.875 183.167C0.875 160.044 19.6195 141.3 42.7419 141.3H369.93L300.601 71.9713C284.251 55.6213 284.251 29.1126 300.601 12.7625Z"
        fill="black"
        fill-opacity="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M213.149 514.913C196.799 531.263 170.29 531.263 153.94 514.913L13.14 374.113C1.16614 362.139 -2.41582 344.131 4.0644 328.487C10.5446 312.842 25.8108 302.642 42.7444 302.642L471.008 302.642C494.131 302.642 512.875 321.386 512.875 344.509C512.875 367.631 494.131 386.376 471.008 386.376L143.82 386.375L213.149 455.704C229.499 472.054 229.499 498.563 213.149 514.913Z"
        fill="#A2A2A2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M213.149 514.913C196.799 531.263 170.29 531.263 153.94 514.913L13.14 374.113C1.16614 362.139 -2.41582 344.131 4.0644 328.487C10.5446 312.842 25.8108 302.642 42.7444 302.642L471.008 302.642C494.131 302.642 512.875 321.386 512.875 344.509C512.875 367.631 494.131 386.376 471.008 386.376L143.82 386.375L213.149 455.704C229.499 472.054 229.499 498.563 213.149 514.913Z"
        fill="url(#paint1_linear_1483_26616)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M213.149 514.913C196.799 531.263 170.29 531.263 153.94 514.913L13.14 374.113C1.16614 362.139 -2.41582 344.131 4.0644 328.487C10.5446 312.842 25.8108 302.642 42.7444 302.642L471.008 302.642C494.131 302.642 512.875 321.386 512.875 344.509C512.875 367.631 494.131 386.376 471.008 386.376L143.82 386.375L213.149 455.704C229.499 472.054 229.499 498.563 213.149 514.913Z"
        fill="black"
        fill-opacity="0.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1483_26616"
          x1="552.209"
          y1="92.4818"
          x2="-222.584"
          y2="90.1485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={startColor ? startColor : "#2CFFE4"} />
          <stop offset="1" stop-color={stopColor ? stopColor : "#A45EFF"} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1483_26616"
          x1="-38.4589"
          y1="435.194"
          x2="736.334"
          y2="437.527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={startColor ? startColor : "#2CFFE4"} />
          <stop offset="1" stop-color={stopColor ? stopColor : "#A45EFF"} />
        </linearGradient>
      </defs>
    </svg>
  );
}
