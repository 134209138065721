import React from "react";

function SuccessNew({ spinnerColor, stopColor }) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.23438 8.90997L7.625 11.1346L12.0948 5.18945"
        stroke="url(#paint0_linear_2764_26377)"
        stroke-width="1.5"
      />
      <path
        d="M16.0563 13.3423C14.5038 15.802 11.778 17.4336 8.67473 17.4336C5.16232 17.4336 2.1335 15.3435 0.742188 12.3281"
        stroke="#8A8F8E"
        stroke-width="0.25"
      />
      <path
        d="M16.0563 13.3423C14.5038 15.802 11.778 17.4336 8.67473 17.4336C5.16232 17.4336 2.1335 15.3435 0.742188 12.3281"
        stroke="#8A8F8E"
        stroke-width="0.25"
      />
      <circle cx="8.68848" cy="8.7041" r="7.48535" stroke="#8A8F8E" />
      <defs>
        <linearGradient
          id="paint0_linear_2764_26377"
          x1="13.3956"
          y1="7.30551"
          x2="0.983954"
          y2="7.29133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={spinnerColor ? spinnerColor : "#2CFFE4"} />
          <stop offset="1" stop-color={stopColor ? stopColor : "#A45EFF"} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SuccessNew;
