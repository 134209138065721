export default function Search({ fill }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.72331 16.4242C11.4052 16.4242 14.39 13.4394 14.39 9.75749C14.39 6.07559 11.4052 3.09082 7.72331 3.09082C4.04141 3.09082 1.05664 6.07559 1.05664 9.75749C1.05664 13.4394 4.04141 16.4242 7.72331 16.4242Z"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0566 18.0908L12.4316 14.4658"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
