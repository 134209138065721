export default function Refetch({ fill }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6068 2.17235C15.2443 2.14296 14.9209 2.40745 14.8883 2.7699L14.8034 3.73642C13.3239 1.7446 10.9364 0.454811 8.24527 0.467872C3.78068 0.490729 0.20116 4.06295 0.210958 8.48415C0.21749 12.8955 3.83293 16.4678 8.28773 16.4678C9.76722 16.4678 11.152 16.0727 12.3441 15.387C12.6903 15.1878 12.785 14.7339 12.5466 14.4139C12.3408 14.1396 11.962 14.0645 11.6648 14.2376C10.6719 14.809 9.51901 15.1355 8.28773 15.1355C4.57104 15.1355 1.55981 12.1543 1.55654 8.47435C1.55328 4.78131 4.55798 1.80011 8.28773 1.80011C10.5674 1.80011 12.5792 2.9201 13.7974 4.63764L12.7458 4.54621C12.38 4.51683 12.0599 4.78131 12.0273 5.14376C11.9946 5.50621 12.2657 5.82294 12.6282 5.85559L15.2573 6.08416C15.2769 6.08416 15.2965 6.08743 15.3161 6.08743C15.6558 6.08743 15.9465 5.82947 15.9759 5.48988L16.2077 2.88745C16.2437 2.525 15.9726 2.205 15.6068 2.17235Z"
        fill={fill}
      />
    </svg>
  );
}
