import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
export default function TxnHash({ txnHash, children }) {
  const [copied, setCopied] = useState(false);
  return (
    <Tooltip
      onClose={() => {
        setTimeout(() => {
          setCopied(false);
        }, [1000]);
      }}
      disableInteractive
      onClick={() => {
        navigator.clipboard.writeText(txnHash).then(() => {
          setCopied(true);
        });
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
      className="bw-text-text-button"
      title={`${copied ? "Copied!" : "Copy"}`}
    >
      {children}
    </Tooltip>
  );
}
