import React from "react";

function SideArrow() {
  return (
    <svg
      width="4"
      height="7"
      viewBox="0 0 4 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.65625 0.539062L3.50318 3.38156L0.793634 6.09111"
        stroke="#E0E0E0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SideArrow;
