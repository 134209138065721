import React from "react";

function Fail({ fill }) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="14" height="14.2277" rx="7" fill={fill} />
      <rect x="0.5" y="0.5" width="14" height="14.2277" rx="7" fill={fill} />
      <rect
        x="0.5"
        y="0.5"
        width="14"
        height="14.2277"
        rx="7"
        stroke="#FD5868"
      />
      <path
        d="M11 11H9.59265C9.49462 11 9.41585 10.9774 9.35634 10.9322C9.30033 10.887 9.25481 10.8354 9.2198 10.7773L7.46062 8.09059C7.43261 8.17128 7.39935 8.24066 7.36084 8.29876L5.67517 10.7773C5.63316 10.8322 5.58415 10.8838 5.52813 10.9322C5.47562 10.9774 5.4056 11 5.31808 11H4L6.52063 7.39834L4.09977 4H5.50713C5.60515 4 5.67517 4.01291 5.71718 4.03873C5.76269 4.06132 5.8047 4.10005 5.84321 4.15491L7.57089 6.72061C7.6059 6.63993 7.64791 6.55924 7.69692 6.47856L9.28282 4.17912C9.32133 4.1178 9.36334 4.07261 9.40885 4.04357C9.45436 4.01452 9.51213 4 9.58215 4H10.9317L8.48987 7.34509L11 11Z"
        fill="#FD5868"
      />
    </svg>
  );
}

export default Fail;
