import React from "react";
import { useMemo, useState } from "react";
import useStore from "../../zustand/store";
import Gas from "../Svg/gas";
import truncate from "../../utils/truncate";
import Down from "../Svg/down";
import DownIcon from "../Svg/down.js";
import ethertousd from "../../utils/ethertousd.js";
import fixString from "../../utils/fixString.js";
function EstGas() {
  const persist = useStore((state) => state.persist);
  const [showFull, setShowFull] = useState(false);
  const [nonPayable, setNonPayable] = useState(false);
  function handleShowFull() {
    setShowFull(!showFull);
  }
  function getGas(amountInEther, decimals) {
    return ethertousd(amountInEther, decimals) || 0;
  }
  function capitalizeFirstLetter(string) {
    if (typeof string !== "string" || string.length === 0) {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const gasData = useMemo(() => {
    let tempGasObj = {
      payable: 0,
      nonPayable: 0,
    };
    persist?.route?.fee?.forEach((item) => {
      if (item.source == "FROM_SOURCE_WALLET") {
        tempGasObj.payable += Number(item.amountInUSD);
      } else if (item.source == "FROM_OUTPUT_AMOUNT") {
        tempGasObj.nonPayable += Number(item.amountInUSD);
      }
    });
    return tempGasObj;
  }, [persist?.route]);
  return (
    <div className="bw-w-full bw-sticky bw-top-0 bw-cursor-pointer bw-border bw-py-2 bw-z-[60] bw-bg-background-container bw-border-border-primary bw-border-x-0">
      {!showFull ? (
        <div className="bw-w-full bw-flex bw-items-center bw-justify-between ">
          <p className="bw-text-sm bw-text-semibold bw-text-text-secondary">
            Fee
          </p>

          <div className="bw-flex bw-items-center bw-gap-x-1">
            <Gas fill={"var(--tw-text-primary)"} />
            <span className="bw-text-sm bw-font-medium bw-text-text-primary">
              {/* {"("} */}
            </span>
            <p className="bw-text-sm bw-font-medium bw-text-text-primary">
              ${truncate(gasData?.payable || 0, 6) || 0}
            </p>
            <span className="bw-text-sm bw-font-medium bw-text-text-primary">
              {/* {")"} */}
            </span>
          </div>
        </div>
      ) : (
        <div className=" bw-select-none">
          <div className="bw-flex bw-items-center bw-justify-between">
            <p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
              Payable
            </p>
            <span className="bw-text-text-secondary bw-text-sm bw-font-bold">
              ${truncate(gasData?.payable, 4)}
            </span>
          </div>
          {persist?.route?.fee
            ?.filter((item, i) => item.source == "FROM_SOURCE_WALLET")
            .map((item, i) => {
              return item.amountInUSD ? (
                <div
                  key={i}
                  className="bw-flex bw-ml-2 bw-items-center bw-justify-between"
                >
                  <p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
                    {`${capitalizeFirstLetter(item.type)} Fee ( ${
                      item.token.blockchain
                    } )`}
                  </p>
                  <span className="bw-text-text-secondary bw-text-sm bw-font-bold">
                    {item.type == "BLOCKEND" && Number(item.amountInUSD) == 0
                      ? "Free"
                      : `${fixString(
                          getGas(
                            item.amountInToken,
                            item.token.decimals
                          ).toFixed(20) || "",
                          10
                        )}  ${item.token.symbol} ($${truncate(
                          Number(item.amountInUSD),
                          4
                        )})`}
                  </span>
                </div>
              ) : (
                <></>
              );
            })}
          <div className="bw-w-full bw-h-[1px] bw-border bw-my-2 bw-border-border-primary"></div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setNonPayable(!nonPayable);
            }}
            className="bw-flex bw-select-none bw-items-center bw-gap-x-1"
          >
            <p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
              Non Payable
            </p>
            <div className={`${nonPayable ? "bw-rotate-180" : ""}`}>
              <DownIcon fill={"var(--tw-text-secondary)"} />
            </div>
          </div>
          {nonPayable ? (
            <>
              {persist?.route?.fee
                ?.filter((item, i) => item.source == "FROM_OUTPUT_AMOUNT")
                .map((item, i) => {
                  return item.amountInUSD ? (
                    <div
                      key={i}
                      className="bw-flex bw-ml-2 bw-items-center bw-justify-between"
                    >
                      <p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
                        {`${capitalizeFirstLetter(item.type)} Fee ( ${
                          item.token.blockchain
                        } )`}
                      </p>
                      <span className="bw-text-text-secondary bw-text-sm bw-font-bold">
                        {`${
                          item.type == "BLOCKEND" &&
                          Number(item.amountInUSD) == 0
                            ? "Free"
                            : `$${truncate(Number(item.amountInUSD), 5)}`
                        }`}
                      </span>
                    </div>
                  ) : (
                    <></>
                  );
                })}{" "}
              <div className="bw-w-full bw-h-[1px] bw-border bw-my-2 bw-border-border-primary"></div>
              <div className="bw-flex bw-items-center bw-justify-between">
                <p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
                  Total Non-Payable Fee
                </p>
                <span className="bw-text-text-secondary bw-text-sm bw-font-bold">
                  $
                  {truncate(
                    gasData?.nonPayable,

                    4
                  )}
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
          <p className="bw-text-[10px] bw-text-text-secondary bw-text-center bw-my-2">
            Output amount is considering non payable fees, there is no
            additional deduction
          </p>
        </div>
      )}
      <div
        onClick={() => {
          handleShowFull();
          setNonPayable(false);
        }}
        className="bw-px-1 bw-border bw-border-border-primary bw-bg-background-container bw-bottom-[-10px] bw-mx-auto bw-left-0 bw-w-max bw-right-0 bw-absolute bw-rounded-sm bw-py-1 bw-flex bw-justify-center bw-items-center"
      >
        <div className={`${showFull ? "bw-rotate-180" : ""}`}>
          <Down fill={"var(--tw-text-secondary)"} />
        </div>
      </div>
    </div>
  );
}

export default EstGas;

// {["Network", "Provider", "Blockend", "Integrator"].map(
//   (item, idx) => {
//     let type = item.toLowerCase();
//     return gasData?.tempGasObj[type] ? (
//       <>
//         {gasData?.tempGasObj[type]?.[fromChain?.chainId] ? (
//           <div className="bw-flex bw-ml-2 bw-mb-1 bw-items-center bw-justify-between">
//             <p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
//               {`${item} Fee (${fromChain?.name})`}
//             </p>
//             <div className="bw-flex bw-flex-col">
//               {Object.entries(
//                 gasData?.tempGasObj[type]?.[fromChain?.chainId]
//               ).map(([key, value]) => {
//                 return (
//                   <span className="bw-text-text-secondary bw-text-right bw-text-sm bw-font-bold">
//                     {`${truncate(
//                       value?.[`${type}InNative`],
//                       4
//                     )} ${key} ($${value?.[`${type}InUSD`] || ""})`}
//                   </span>
//                 );
//               })}
//             </div>
//           </div>
//         ) : (
//           <></>
//         )}
//         {gasData?.tempGasObj[type]?.[toChain?.chainId] ? (
//           <div className="bw-flex bw-mb-1 bw-ml-2 bw-items-center bw-justify-between">
//             <p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
//               {`${item} Fee (${toChain?.name})`}
//             </p>
//             <div>
//               {Object.entries(
//                 gasData?.tempGasObj[type]?.[toChain?.chainId]
//               ).map(([key, value]) => {
//                 console.log(key, value, "key2");
//                 return (
//                   <span className="bw-text-text-secondary bw-text-sm bw-font-bold">
//                     {`${truncate(
//                       value?.[`${type}InNative`],
//                       4
//                     )} ${key} ($${value?.[`${type}InUSD`] || ""})`}
//                   </span>
//                 );
//               })}
//             </div>
//           </div>
//         ) : (
//           <></>
//         )}
//       </>
//     ) : (
//       <></>
//     );
//   }
// )}

{
  /* <div className="bw-flex bw-ml-2 bw-items-center bw-justify-between">
<p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
  Provider Fee
</p>
<span className="bw-text-text-secondary bw-text-sm bw-font-bold">
  ${truncate(gasData?.tempGasObj?.payNonProviderInUSD || 0, 4)}
</span>
</div>
<div className="bw-flex bw-ml-2 bw-items-center bw-justify-between">
<p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
  Blockend Fee
</p>
<span className="bw-text-text-secondary bw-text-sm bw-font-bold">
  {gasData?.tempGasObj?.payNonBlockendInUSD
    ? ` $${truncate(
        gasData?.tempGasObj?.payNonBlockendInUSD,
        4
      )}`
    : "Free"}
</span>
</div>
<div className="bw-flex bw-ml-2 bw-items-center bw-justify-between">
<p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
  Integrator Fee
</p>
<span className="bw-text-text-secondary bw-text-sm bw-font-bold">
  {gasData?.tempGasObj?.payNonIntegratorInUSD
    ? ` $${truncate(
        gasData?.tempGasObj?.payNonIntegratorInUSD,
        4
      )}`
    : 0}
</span>
</div> */
}
