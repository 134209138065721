export default function SwapIcon({ fill1, fill2 }) {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18.5027"
        cy="18.6207"
        r="18.2551"
        transform="rotate(90 18.5027 18.6207)"
        fill={fill1}
      />
      <path
        d="M14.3655 7.6488L7.58782 16.5705L28.9581 16.5705L28.9581 12.7663L14.3655 12.7663L14.3655 7.6488Z"
        fill={fill2}
        fill-opacity="1"
      />
      <path
        d="M22.2517 29.0189L29.0294 20.0972L7.65911 20.0972L7.65911 23.9014L22.2517 23.9014L22.2517 29.0189Z"
        fill={fill2}
        fill-opacity="1"
      />
    </svg>
  );
}
