import React, { useEffect, useRef } from "react";
import { colorify, flatten, replaceColor, getColors } from "lottie-colorify";
import Lottie from "lottie-web";
import activeState from "./activestate.json";
import useStore from "../zustand/store";
function ActiveState() {
  const container = useRef(null);
  const configuration = useStore((state) => state.configuration);
  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: container.current,
      animationData: flatten(
        configuration?.gradientStyle?.spinnerColor
          ? configuration?.gradientStyle?.spinnerColor
          : "#2CFFE4",

        activeState
      ),
    });
    return () => animation.destroy();
  }, []);
  return <div style={{ width: "100%", height: "100%" }} ref={container}></div>;
}

export default ActiveState;
