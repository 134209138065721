import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useEffect, useState } from "react";

export default function useTronBalance(fromChain) {
  const { wallet, address } = useWallet();
  const [balance, setBalance] = useState(0);
  async function getBalance() {
    const balanceTron = await window.tronWeb.trx.getBalance(address);
    setBalance(balanceTron / Math.pow(10, 6));
  }
  useEffect(() => {
    if (address && fromChain?.networkType === "tron") getBalance();
  }, [address, fromChain?.networkType]);
  // console.log(balance, "balance tron");
  return { tronBal: balance };
}
