import React from "react";
import styles from "./TimeLoaderHistory.module.css";
function TimeLoaderHistory() {
  return (
    <div className={styles.loader}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default TimeLoaderHistory;
