import React from "react";
import useStore from "../../zustand/store";
import styles from "./SelectWallet.module.css";
import images from "../../images";
function WalletTab({ selectedWalletTab, handleWalletTab }) {
  const walletArray = ["All", "EVM", "SVM", "Cosmos", "Other"];
  const configuration = useStore((state) => state.configuration);
  const { cosmos, evmwal, solwal, walleticon } = images;
  const walletIcon = {
    EVM: evmwal,
    SVM: solwal,
    Cosmos: cosmos,
    Other: walleticon,
  };
  return (
    <div className="bw-flex bw-px-3 bw-justify-between bw-gap-x-2 bw-items-center">
      {walletArray.map((wallet, index) => (
        <div
          style={
            selectedWalletTab == wallet
              ? {
                  ...configuration?.gradientStyle,
                }
              : {}
          }
          className={`${
            !configuration.gradientStyle &&
            selectedWalletTab == wallet &&
            styles.gradientbutton
          } bw-min-h-[2px] bw-rounded-md bw-p-[1px] bw-mt-[2px] bw-min-w-[40px]`}
        >
          <div
            key={index}
            onClick={() => {
              handleWalletTab(wallet);
            }}
            className={`bw-w-[57px] bw-cursor-pointer bw-h-[44px] bw-flex ${
              selectedWalletTab === wallet ? "bw-bg-background-container" : ""
            } bw-rounded-md bw-relative bw-flex-col bw-justify-center bw-items-center`}
          >
            {
              <div
                className={`${
                  selectedWalletTab === wallet
                    ? ""
                    : "bw-bg-background-secondary bw-opacity-[20%]"
                } bw-h-full bw-absolute bw-top-0 bw-right-0 bw-rounded-md  bw-w-full`}
              ></div>
            }

            {walletIcon[wallet] ? (
              <img src={walletIcon[wallet]} alt="img" />
            ) : (
              <></>
            )}
            <p
              className={`${
                selectedWalletTab === wallet
                  ? " bw-text-text-primary bw-font-bold"
                  : " bw-text-text-secondary bw-font-normal"
              } bw-text-xs bw-mt-[2px] `}
            >
              {wallet}
            </p>
            {/* {selectedWalletTab === wallet ? (
            <div
              style={{
                ...configuration?.gradientStyle,
              }}
              className={`${
                !configuration.gradientStyle && styles.gradientbutton
              } bw-min-h-[2px] bw-mt-[2px] bw-min-w-[40px]`}
            ></div>
          ) : (
            <></>
          )} */}
          </div>
        </div>
      ))}
    </div>
  );
}

export default WalletTab;
